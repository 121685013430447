import React, { useRef, useState, useEffect } from 'react'
import { motion, useScroll, useTransform } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import "../css/latest-release.css"
import { client } from '../client'
import { Link, useNavigate } from 'react-router-dom'
import latestReleaseText from "../assets/latestrelease.svg"
import merch from "../assets/pinball2000.jpg"

const LatestSection = ({ smart, latestImage, setOverlay }) => {

    const { ref: laundryTrigger, inView: laundry } = useInView()
    const { ref: trigger1, inView: text1 } = useInView()
    const { ref: trigger2, inView: merchText } = useInView()
    const { ref: trigger3, inView: merchTextActive } = useInView()
    const targetRef = useRef()
    const [ latest2, setLatest2 ] = useState()
    const [ latest3, setLatest3 ] = useState()

    const navigate = useNavigate()

    const overlayTrigger = async () => {
        setOverlay(true)
          setTimeout(() => {
            navigate("/music")
            setOverlay(false)
          }, 1000)
     } 


    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end start"]
})

    const positionY = useTransform(scrollYProgress, [0, 0.7, 0.8, 1], ["800px", "0px", "0px", "-150px"])
    const opacity1 = useTransform(scrollYProgress, [0.7, 0.775], [0, 1])
    const y = useTransform(scrollYProgress, [0.8, 1], ["100vh", "0vh"])


  return (
    <div ref={ laundryTrigger } style={{ position: "relative", height: "200vh", width: "100vw", zIndex: 100 }}>
        <motion.div className="latest-container" ref={ targetRef }>
            <div style={{ height: "30vh", position: "relative" }}></div>
            <div ref={ trigger1 } style={{ height: "100vh", position: "relative" }}></div>
            <motion.div style={{ display: "flex", alignItems: "center", justifyContent: "center", pointerEvents: "none", opacity: opacity1 }} className="latest-bg">
                <img src={latestReleaseText} style={{ height: "160vh", marginLeft: "0" }} />
            </motion.div>
        <motion.div style={{ position: "fixed", height: "100vh", width: "100vw", top: 0, overflow: "hidden", y: positionY, zIndex: 100, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <motion.div className="latest-image-container" style={{ position: "relative", zIndex: 100 }}>
                <div style={{ overflow: "hidden" }}>
                <a href={ smart } target="_blank">
                <motion.div animate={ latest2 ? {opacity: 1} : {opacity: 0} } onMouseEnter={() => {setLatest2(true)}} onMouseLeave={() => {setLatest2(false)}}  style={{ position: "absolute", cursor: "pointer", width: "100%", height: "100%", backgroundColor: "#09090999", zIndex: 1000, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <motion.div animate={ latest2 ? {rotate: "45deg"} : {rotate: 0} } transition={{ ease: [0.5, 0.7, 0.05, 0.9], duration: 0.5 }}><svg width="50" height="50">
                        <line x1="48" y1="2" x2="2" y2="48" stroke="white" />
                        <line x1="48" y1="2" x2="48" y2="48" stroke="white" />
                        <line x1="2" y1="2" x2="48" y2="2" stroke="white" />
                    </svg>
                </motion.div>
                </motion.div></a>
                { laundry &&
                <motion.img animate={ latest2 ? { scale: 1.05 } : { scale: 1 } } transition={{ duration: 0.5, ease: "easeIn" }} dragConstraints={{ x: 0 }} src={ latestImage } style={{ objectFit: "cover", height: "100%", width: "100%", zIndex: 900, position: "relative" }} />
                }
                </div>
                <motion.div onMouseEnter={() => {setLatest2(true)}}  onMouseLeave={() => {setLatest2(false)}} animate={ text1 ? { opacity: 1 } : { opacity: 0 } } className="latest-more" style={{ top: "-50px", cursor: "pointer", fontSize: "clamp(0.8rem, 1vw, 1.2rem)" }}>
                    <motion.a href={ smart } target="_blank" animate={ latest2 ? { opacity: 0.7 } : { opacity: 1 }}>Latest release</motion.a>
                </motion.div>
                <motion.a href="#" onMouseEnter={() => {setLatest3(true)}}  onMouseLeave={() => {setLatest3(false)}} animate={ latest3 ? { opacity: 0.7 } : { opacity: 1 }} onClick={overlayTrigger} className="latest-more" style={{ marginTop: "10px", cursor: "pointer", fontSize: "clamp(0.8rem, 1vw, 1.2rem)" }}>Listen to more music here
                </motion.a>
            </motion.div>
        </motion.div>
        </motion.div>
        
        <motion.div style={{ position: "fixed", y, width:"100vw", bottom: 0, zIndex: 500, overflow: "hidden", backgroundColor: "red" }} >
            <motion.div style={{ height: "100vh", width:"100vw", bottom: 0, alignItems: "start", display: "flex" }}>
                <div className="merch-overlay" style={{ background: "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)", zIndex: 550, width: "100vw", height: "100vh", position: "fixed", top: 0 }}></div>
                <img src={ merch } width="100%" height="100%" style={{ objectFit: "cover", objectPosition: "top", top: 0, position: "absolute" }} />
            </motion.div>
        </motion.div>
        { merchTextActive &&
        <>
        <motion.div className="merch1" initial={{ opacity: 0 }} animate={ merchText ? { opacity: 1 } : { opacity: 0 }}>Merchandise<h1 className="merch1-2" style={{ letterSpacing: "-0.08em", lineHeight: 0.8, fontSize: "clamp(3rem, 5vw, 5rem)", position: "relative", top: -20, left: -8 }}>
            <motion.span animate={ merchText ? { opacity: 1, transition: { delay: 0.1, duration: 1 } } : { opacity: 0 }}>SHOP</motion.span><br /><motion.span animate={ merchText ? { opacity: 1, transition: { delay: 0.2, duration: 1 } } : { opacity: 0 }}>GALANTIS</motion.span></h1>
        </motion.div>
        <motion.div className="merch2" initial={{ opacity: 0 }} animate={ merchText ? { opacity: 1, transition: { delay: 0.3, duration: 1 } } : { opacity: 0 }}>International Stores<h1 className="merch2-2" style={{ letterSpacing: "-0.08em", lineHeight: 0.8, fontSize: "clamp(3rem, 5vw, 5rem)", cursor: "pointer", position: "relative", top: -20, left: -8 }}>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#8349d3" }}  href='https://galantis.merchtable.com/' target="_blank" animate={ merchText ? { opacity: 1, transition: { delay: 0.4, duration: 1 } } : { opacity: 0 }}>US /</motion.a> <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#8349d3" }} href='https://shop.mu-mo.net/gjos/' target="_blank" animate={ merchText ? { opacity: 1, transition: { delay: 0.5, duration: 1 } } : { opacity: 0 }}>JAPAN /</motion.a><br />
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#8349d3" }} href='https://store.100-percent.co.uk/collections/galantis' target="_blank" animate={ merchText ? { opacity: 1, transition: { delay: 0.6, duration: 1 } } : { opacity: 0 }}>UK & EUROPE</motion.a></h1>
        </motion.div>
        </>
        }
        <div style={{ position: "relative", height: "90vh", width: "20vw", zIndex: 601, pointerEvents: "none" }}></div>
        <div ref={ trigger3 } style={{ position: "relative", height: "10vh", width: "20vw", zIndex: 601, pointerEvents: "none" }}></div>
        <div ref={ trigger2 } style={{ position: "relative", height: "5vh", width: "20vw", zIndex: 601, pointerEvents: "none" }}></div>
    </div>
  )
}

export default LatestSection