import React from 'react'
import "../css/latest-button.css"
import { motion, useTransform } from 'framer-motion';

const LatestButton = ({ smart, opacity, scrollY }) => {


    let x = useTransform( scrollY, [0, 400, 500], [0, 0, -1000])


  return (
    <motion.div style={{ x }} className="wrapper">
        <motion.div style={{ opacity }} className="latest-button-container">
            <div className="latest-button-grid">
                <div className='play-button'>
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="0,20 10,5 10,15 20,0" fill='white' />
                    </svg>
                </div>
                <a style={{ textDecoration: "none" }} href={smart} target="_blank" className='latest-release-text'>
                    <motion.div animate={{ x: -260 }} transition={{ duration: 5, repeat: Infinity, ease: "linear" }} className="latest-release-marquee">LATEST RELEASE · LATEST RELEASE · LATEST RELEASE · LATEST RELEASE</motion.div>
                </a>
            </div>
        </motion.div>
    </motion.div>
  )
}

export default LatestButton