import React from 'react'
import Releases from "./Releases" 
import { Canvas } from '@react-three/fiber'


const ReleasesContainer = ({PharmacyMid, YouMid, AviaryMid, ChurchMid, RunawayMid, PBJMid, HeartbreakMid, DamnMid, NoMoneyMid, FaithMid, windowWidth, pharmacyt2, pharmacyt4, pharmacyt5, pharmacyt6, pharmacyt62, pharmacyt7}) => {


  return (
    <>
        <Canvas style={{ zIndex: 800 }}  camera={{position: [-1, 0, 5]}} >
            <Releases PharmacyMid={ PharmacyMid } YouMid={ YouMid } RunawayMid={ RunawayMid } AviaryMid={ AviaryMid } ChurchMid={ ChurchMid } PBJMid={PBJMid} HeartbreakMid={ HeartbreakMid } DamnMid={ DamnMid } NoMoneyMid={ NoMoneyMid } FaithMid={ FaithMid }
            windowWidth={windowWidth} pharmacyt2={pharmacyt2} pharmacyt4={pharmacyt4} pharmacyt5={pharmacyt5} pharmacyt6={ pharmacyt6 } pharmacyt62={ pharmacyt62 } pharmacyt7={ pharmacyt7 } />
        </Canvas>
    </>
  )
}

export default ReleasesContainer