import React from "react"
import "../css/terms.css"
import { motion } from "framer-motion"
import { TermsContext } from "../helpers/TermsContext"
import { useContext } from "react"
import { ScrollContext } from "../helpers/ScrollContext"

const Terms = ({ windowWidth }) => {

const { terms, showTerms } = useContext(TermsContext)
const { scroller, setScroller } = useContext(ScrollContext)

const closeTerms = () => {
    showTerms(false)
    setScroller(false)
}

const termsParent = {
    closed: { opacity: 1 },
    open: { opacity: 1, transition: { delayChildren: 0.7, staggerChildren: 0.15 }}
}

const termsParagraph = {
    closed: { y: 5, opacity: 0 },
    open: { y: 0, opacity: 1, transition: { duration: 0.9 } }
}

console.log(windowWidth)


return (

<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} className="shade-layer">
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3, duration: 0.6 } }} className="terms-frame">
        <motion.div style={ windowWidth < 700 ? { top: 200, width: "100%", position: "relative" } : { top: 0, width: "100%" } } initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.5, duration: 0.6 } }} className="terms-title">Terms & Conditions</motion.div>
        <motion.div whileHover={{ rotate: 180, transition: { ease: "linear"} }} onClick={() => {closeTerms()}} className="terms-cross">
            <div className="terms-cross-1"></div>
            <div className="terms-cross-2"></div>
        </motion.div>
        <div className="middle-box">
            <motion.div variants={termsParent} initial="closed" animate="open" className="terms-text">
                <motion.p variants={termsParagraph}><strong>1. AGREEMENT TO THESE TERMS OF USE</strong></motion.p>
                <motion.p variants={termsParagraph}>PLEASE READ THESE TERMS OF SITE CAREFULLY. YOUR USE OF THE SITE CONSITUTES YOUR AGREEMENT TO THIS TERMS OF USE AGREEMENT. Galantis Limited (“Galantis” “we,” “us”) provides this website and all site-related services (collectively, the “Site”) subject to your compliance with the terms and conditions set forth in these terms (the “Terms”). Other services and websites offered by Galantis may be subject to separate terms, including but not limited to www.wearegalantis.com. Galantis may, at its discretion, update these Terms at any time. You can access and review the most current version of these Terms at the URL for this page or by clicking on the "Terms of Use" link at the bottom of each page of the Site or otherwise made available within the Site.</motion.p>
                <motion.p variants={termsParagraph}>By using the Site, you agree to be bound by these Terms. The Site may also provide rules of participation (“Rules”) for certain activities and services including, without limitation, contests and sweepstakes, games and membership clubs. To the extent that there is a conflict between these Terms and such Rules for the activity in which you choose to participate, the Rules shall govern. These Terms will remain in full force and effect as long as you are a user of the Site and in the event of termination of any membership, service or feature, you will still be bound by your obligations under these Terms or Rules, including any indemnifications, warranties and limitations of liability. You acknowledge that you have read, understood, and agree to be bound by these Terms. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, YOU SHOULDN'T, AND ARE NOT PERMITTED TO, ACCESS OR USE THE SITE.</motion.p>
                <motion.p variants={termsParagraph}><strong>2. USE BY MINORS AND BLOCKED PERSONS</strong></motion.p>
                <motion.p variants={termsParagraph}>The Site is not available to persons under the age of 13. If you are between the ages of 13 and the age of legal majority in your jurisdiction of residence, you may only use the Site under the supervision of a parent or legal guardian who agrees to be bound by these Terms.</motion.p>
                <motion.p variants={termsParagraph}>BY USING THE SITE, YOU REPRESENT THAT YOU ARE AT LEAST 13 YEARS OF AGE AND THAT YOUR PARENT OR LEGAL GUARDIAN AGREES TO BE BOUND BY THESE TERMS OF SITE IF YOU ARE BETWEEN 13 AND THE AGE OF LEGAL MAJORITY IN YOUR JURISDICTION OF RESIDENCE.</motion.p>
                <motion.p variants={termsParagraph}><strong>3. PRIVACY POLICY</strong></motion.p>
                <motion.p variants={termsParagraph}>In addition to these Terms, Galantis's Privacy Policy governs your access to and use of the Site which is available at https://wearegalantis.com/privacy-policy/. You agree that by using the Site you consent to the collection, use, and sharing of such information (as set forth in the Privacy Policy), including the transfer of this information within or to the United States and/or other countries for storage, processing, and use by Galantis, its affiliates, and its third-party business associates. </motion.p>
                <motion.p variants={termsParagraph}><strong>4. NEWSLETTER</strong></motion.p>
                <motion.p variants={termsParagraph}>We use Mailchimp as our marketing platform. By clicking the link to subscribe to our newsletter, you acknowledge that your information will be transferred to Mailchimp for processing and is subject to Mail Chimp’s privacy policy available at https://mailchimp.com/legal/. By subscribing to the newsletter, you acknowledge you have reviewed and agreed to such privacy policy. You can unsubscribe at any time by clicking the link in the footer of our emails. We will use the information you provide to be in touch with you and to provide updates and marketing.</motion.p>
                <motion.p variants={termsParagraph}><strong>5. RULES FOR SWEEPSTAKES, CONTESTS AND GAMES</strong></motion.p>
                <motion.p variants={termsParagraph}>In addition to these Terms, any sweepstakes, contests, games or similar promotions (collectively, “Promotions”) made available through the Site may be governed by specific Rules that are separate from these Terms. By participating in any such Promotion, you will become subject to those rules. We urge you to review any specific Rules applicable to a particular Promotion, which will be linked from such Promotion, and to review our Privacy Policy which, in addition to these Terms, governs any information you submit in connection with such activities. To the extent that any such Rules conflict with these Terms, the terms and conditions of such Rules shall control with respect to the particular Promotion.</motion.p>
                <motion.p variants={termsParagraph}><strong>6. MODIFICATION OF THESE TERMS OF SITE</strong></motion.p>
                <motion.p variants={termsParagraph}>Galantis reserves the right to amend any of these Terms by posting the amended terms. Your continued use of the Site after the effective date of the revised Terms constitutes your acceptance of the terms.</motion.p>
                <motion.p variants={termsParagraph}>For residents of the Republic of Korea, Galantis will provide reasonable prior notice regarding any material amendments to its Terms.  All amendments shall become effective no sooner than 30 calendar days after posting; provided that any amendment regarding newly available features of the Site, features of the Site that are beneficial to the user, or changes made for legal reasons may become effective immediately.</motion.p>
                <motion.p variants={termsParagraph}><strong>7. RIGHTS</strong></motion.p>
                <motion.p variants={termsParagraph}>The Site is owned and operated by Galantis. Unless otherwise indicated, all content, information, and other materials on the Site, including, without limitation, Galantis's trademarks and logos, the visual interfaces, graphics, design, compilation, information, software, computer code (including source code or object code), services, text, pictures, information, data, sound files, other files, and the selection and arrangement thereof (collectively, the “Materials”) are protected by relevant intellectual property and proprietary rights and laws. All Materials are the property of Galantis or its subsidiaries or affiliated companies and/or third-party licensors. Unless otherwise expressly stated in writing by Galantis , by agreeing to these Terms you are granted a limited, non-sublicensable license (i.e., a personal and limited right) to access and use the Site for your personal use or internal business use only.</motion.p>
                <motion.p variants={termsParagraph}>Galantis reserves all rights not expressly granted in these Terms. This license is subject to these Terms and does not permit you to engage in any of the following: (a) resale or commercial use of the Site or the Materials; (b) distribution, public performance or public display of any Materials; (c) modifying or otherwise making any derivative uses of the Site or the Materials, or any portion of them; (d) use of any data mining, robots, or similar data gathering or extraction methods; (e) downloading (except page caching) of any portion of the Site, the Materials, or any information contained in them, except as expressly permitted on the Site; or (f) any use of the Site or the Materials except for their intended purposes. Any use of the Site or the Materials except as specifically authorized in these Terms, without the prior written permission of Galantis, is strictly prohibited and may violate intellectual property rights or other laws. Unless explicitly stated in these Terms, nothing in them shall be interpreted as conferring any license to intellectual property rights, whether by estoppel, implication, or other legal principles. Galantis can terminate this license as set out in Section 13.</motion.p>
                <motion.p variants={termsParagraph}><strong>8. PROHIBITED CONDUCT</strong></motion.p>
                <motion.p variants={termsParagraph}>YOU AGREE NOT TO violate any law, contract, intellectual property, or other third-party right; not to commit a tort, and that you are solely responsible for your conduct while on the Site.</motion.p>
                <motion.p variants={termsParagraph}>You agree that you will comply with these Terms and will not:</motion.p>
                <motion.p variants={termsParagraph}>I. impersonate any person or entity; falsely claim an affiliation with any person or entity; access the Site accounts of others without permission; forge another person’s digital signature; misrepresent the source, identity, or content of information transmitted via the Site; or perform any other similar fraudulent activity;</motion.p>
                <motion.p variants={termsParagraph}>II. engage in spamming or flooding;</motion.p>
                <motion.p variants={termsParagraph}>III. Restrict or inhibit any other visitor or member from using the Site;</motion.p>
                <motion.p variants={termsParagraph}>IV. harvest or collect email addresses or other contact information of other users from the Site;</motion.p>
                <motion.p variants={termsParagraph}>V. defame, harass, abuse, threaten, or defraud users of the Site, or collect or attempt to collect, personal information about users or third parties without their consent;</motion.p>
                <motion.p variants={termsParagraph}>VI. delete, remove, circumvent, disable, damage, or otherwise interfere with (a) security-related features of the Site or User Content, (b) features that prevent or restrict use or copying of any content accessible through the Site, (c) features that enforce limitations on the use of the Site or User Content, or (d) the copyright or other proprietary rights notices on the Site or User Content;</motion.p>
                <motion.p variants={termsParagraph}>VII. reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code of the Site or any part thereof, except and only to the extent that this activity is expressly permitted by the law of your jurisdiction of residence;</motion.p>
                <motion.p variants={termsParagraph}>VIII. modify, adapt, translate, or create derivative works based upon the Site or any part thereof, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation;</motion.p>
                <motion.p variants={termsParagraph}>IX. interfere with or damage the operation of the Site or any user’s enjoyment of them, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code;</motion.p>
                <motion.p variants={termsParagraph}>X. access any website, server, software application, or other computer resource owned, used, and/or licensed by Galantis, including but not limited to the Site, by means of any robot, spider, scraper, crawler, or other automated means for any purpose, or bypass any measures Galantis may use to prevent or restrict access to any website, server, software application, or other computer resource owned, used, and/or licensed by Galantis, including but not limited to the Site;</motion.p>
                <motion.p variants={termsParagraph}>XI. manipulate identifiers in order to disguise the origin of any User Content transmitted through the Site;</motion.p>
                <motion.p variants={termsParagraph}>XII. interfere with or disrupt the Site or servers or networks connected to the Site, or disobey any requirements, procedures, policies, or regulations of networks connected to the Site; use the Site in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Site, or that could damage, disable, overburden, or impair the functioning of the Site in any manner;</motion.p>
                <motion.p variants={termsParagraph}>XIII. attempt to circumvent any content filtering techniques we employ, or attempt to access any Site or area of the Site that you are not authorized to access;</motion.p>
                <motion.p variants={termsParagraph}>XIV. “Frame” or “mirror” any part of the Site without our prior written authorization;</motion.p>
                <motion.p variants={termsParagraph}>XV. attempt to indicate in any manner, without our prior written permission, that you have a relationship with us or that we have endorsed you or any products or Sites for any purpose; and</motion.p>
                <motion.p variants={termsParagraph}>XVI. use the Site for any illegal purpose, or in violation of any local, state, national, or international law or regulation, including without limitation laws governing intellectual property and other proprietary rights, data protection, and privacy.</motion.p>
                <motion.p variants={termsParagraph}><strong>9. COPYRIGHT POLICY</strong></motion.p>
                <motion.p variants={termsParagraph}>Galantis has adopted the following policy toward copyright infringement on the Site. If you believe that content residing or accessible on or through the Site infringes a copyright, please send a notice of copyright infringement containing the following information to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>:</motion.p>
                <motion.p variants={termsParagraph}>
                    <ul>
                        <li>Identification of the work or material being infringed.<br /><br /></li>
                        <li>Identification of the material that is claimed to be infringing, including its location, with sufficient detail so that Galantis is capable of finding it and verifying its existence.<br /><br /></li>
                        <li>Contact information for the notifying party (the "Notifying Party"), including name, address, telephone number, and email address.<br /><br /></li>
                        <li>A statement that the Notifying Party has a good faith belief that the material is not authorized by the copyright owner, its agent or law.<br /><br /></li>
                        <li>A statement made under penalty of perjury that the information provided in the notice is accurate and that the Notifying Party is authorized to make the complaint on behalf of the copyright owner.<br /><br /></li>
                    </ul> </motion.p>
                <motion.p variants={termsParagraph}><strong>10. TRADEMARKS</strong></motion.p>
                <motion.p variants={termsParagraph}>All trademarks including, Galantis, the Galantis logos, and any other product or Site name, logo, or slogan used by Galantis, and the look and feel of the Site, including all page headers, custom graphics, button icons, and scripts, are trademarks or trade dress of Galantis, and may not be used in whole or in part in connection with any product or Site that is not Galantis’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Galantis, without our prior written permission. </motion.p>
                <motion.p variants={termsParagraph}>All other trademarks referenced in the Site are the property of their respective owners. Reference on the Site to any products, Sites, processes, or other information by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation thereof by us or any other affiliation.</motion.p>
                <motion.p variants={termsParagraph}><strong>11. THIRD-PARTY CONTENT</strong></motion.p>
                <motion.p variants={termsParagraph}>The Site may provide links to other websites or resources. You acknowledge and agree that Galantis does not endorse and is not responsible for any content, advertising, products, services or other materials on or available through such sites or resources ("External Materials"). These sites are subject to different terms of use and privacy policies, which you are responsible for reviewing. You further acknowledge and agree that Galantis shall not be liable for any damage or loss caused by or resulting from use of or reliance on any External Materials. Access and use of External Materials, including the information, materials, products, and Sites on or available through External Materials is solely at your own risk.</motion.p>
                <motion.p variants={termsParagraph}>Your dealings with advertisers and other third parties who market, sell, buy or offer to sell or buy any goods or Sites on the Site, including payment for or delivery of such goods or Sites and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and the advertiser or other third party. You agree that Galantis shall not be liable for any loss or damage of any kind incurred as a result of any such dealings.</motion.p>
                <motion.p variants={termsParagraph}><strong>12. IDEA SUBMISSION</strong></motion.p>
                <motion.p variants={termsParagraph}>If you elect to provide or make available to Galantis any suggestions, comments, ideas, improvements or other feedback relating to the Site ("Suggestions"), Galantis shall be free to use, disclose, reproduce, have made, modify, license, transfer and otherwise utilize and distribute your Suggestions in any manner, without credit or compensation to you. </motion.p>
                <motion.p variants={termsParagraph}>Unsolicited Submissions. Notwithstanding anything to the contrary in this Agreement, we and our affiliates do not accept, invite, or consider unsolicited submissions of ideas, proposals or suggestions (“Unsolicited Submissions”), whether related to the Site, our Products or otherwise. We do not treat Unsolicited Submissions as confidential, and any Unsolicited Submissions will become our or our affiliate’s sole property. We and our affiliates have no obligations with respect to Unsolicited Submissions and may use them for any purpose whatsoever without compensation to you or any other person.</motion.p>
                <motion.p variants={termsParagraph}><strong>13. TERMINATION</strong></motion.p>
                <motion.p variants={termsParagraph}>To the fullest extent permitted by applicable law, Galantis reserves the right, without notice and in our sole discretion, to terminate your license to use the Site (including to post User Content) and to block or prevent your future access to and use of the Site, including where we reasonably consider that: (a) your use of the Site violates these Terms or applicable law; (b) you fraudulently use or misuse the Site; or (c) we are unable to continue providing the Site to you due to technical or legitimate business reasons. Our right to terminate your license includes the ability to terminate or to suspend your access to any purchased products or Sites, including any subscriptions. To the fullest extent permitted by applicable law, your only remedy with respect to any dissatisfaction with: (i) the Site, (ii) any term of these Terms, (iii) any policy or practice of Galantis in operating the Site, or (iv) any content or information transmitted through the Site, is to terminate your account and to discontinue use of any and all parts of the Site.</motion.p>
                <motion.p variants={termsParagraph}>For residents of the Republic of Korea, except in the case where Galantis reasonably considers that giving notice is legally prohibited (for instance, when providing notice would either (i) violate applicable laws, regulations, or orders from regulatory authorities or (ii) compromise an ongoing investigation conducted by a regulatory authority) or that any notice may cause harm to you, third parties, Galantis , and/or its affiliates (for instance, when providing notice harms the security of the Site), Galantis will without delay notify you of the reason for taking the relevant step.</motion.p>
                <motion.p variants={termsParagraph}><strong>17. DISPUTES</strong></motion.p>
                <motion.p variants={termsParagraph}><strong>a. Indemnification</strong></motion.p>
                <motion.p variants={termsParagraph}>To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless Galantis, its affiliated companies, and each of our respective contractors, employees, officers, directors, agents,  third-party suppliers, licensors, and partners, (individually and collectively, the “Galantis Parties”) from any claims, losses, damages, demands, expenses, costs, and liabilities, including legal fees and expenses, arising out of or related to your access, use, or misuse of the Site, any information you post, store, or otherwise transmit in or through the Site, your violation of the rights of any third party, any violation by you of these Terms, or any breach of the representations, warranties, and covenants made by you herein. You agree to promptly notify the Galantis Parties of any third-party claim, and Galantis reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify Galantis, and you agree to cooperate with Galantis’s defense of these claims. Galantis will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.</motion.p>
                <motion.p variants={termsParagraph}><strong>b. Disclaimers; No Warranties</strong></motion.p>
                <motion.p variants={termsParagraph}>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) THE SITES AND THE CONTENT AND MATERIALS CONTAINED THEREIN ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY GALANTIS; (B) THE GALANTIS PARTIES DISCLAIM ALL OTHER WARRANTIES, STATUTORY, EXPRESS, OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT AS TO THE SITES, INCLUDING ANY INFORMATION, CONTENT, OR MATERIALS CONTAINED THEREIN; (C) GALANTIS DOES NOT REPRESENT OR WARRANT THAT THE CONTENT OR MATERIALS ON THE SITES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE; (D) GALANTIS IS NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS RELATING TO TEXT OR PHOTOGRAPHY; AND (E) WHILE GALANTIS ATTEMPTS TO MAKE YOUR ACCESS AND USE OF THE SITES SAFE, GALANTIS CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SITES OR OUR SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, AND THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM GALANTIS OR THROUGH THE SITES WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM GALANTIS OR THROUGH THE SITE, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</motion.p>
                <motion.p variants={termsParagraph}><strong>c. Limitation of Liability and Damages</strong></motion.p>
                <motion.p variants={termsParagraph}><strong>i. Limitation of Liability</strong></motion.p>
                <motion.p variants={termsParagraph}>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) IN NO EVENT SHALL GALANTIS OR THE GALANTIS PARTIES BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE THE SITES, THE CONTENT OR THE MATERIALS, INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE ON ANY INFORMATION OBTAINED FROM GALANTIS, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED ACCESS TO GALANTIS’s RECORDS, PROGRAMS, OR SITES; AND (B) IN NO EVENT SHALL THE AGGREGATE LIABILITY OF GALANTIS, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE, OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY, OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SITES EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE SITES DURING THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF THE CLAIM OR ONE HUNDRED DOLLARS, WHICHEVER IS GREATER. TO THE EXTENT THAT APPLICABLE LAW PROHIBITS LIMITATION OF SUCH LIABILITY, GALANTIS SHALL LIMIT ITS LIABILITY TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW.</motion.p>
                <motion.p variants={termsParagraph}><strong>ii. Reference Sites</strong></motion.p>
                <motion.p variants={termsParagraph}>THESE LIMITATIONS OF LIABILITY ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY YOU BY REASON OF ANY PRODUCTS OR SITES SOLD OR PROVIDED ON ANY REFERENCE SITES OR OTHERWISE BY THIRD PARTIES OTHER THAN GALANTIS AND RECEIVED THROUGH OR ADVERTISED ON THE SITES OR RECEIVED THROUGH ANY REFERENCE SITES.</motion.p>
                <motion.p variants={termsParagraph}><strong>iii. Basis of the Bargain</strong></motion.p>
                <motion.p variants={termsParagraph}>YOU ACKNOWLEDGE AND AGREE THAT GALANTIS HAS OFFERED THE SITES, MATERIALS, AND OTHER CONTENT AND INFORMATION, AND ENTERED INTO THESE TERMS OF SITE IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND GALANTIS, AND THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND GALANTIS. GALANTISWOULD NOT BE ABLE TO PROVIDE THE SITES TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.</motion.p>
                <motion.p variants={termsParagraph}>d. Applicable Law and Venue</motion.p>
                <motion.p variants={termsParagraph}>(i) To the fullest extent permitted by applicable law, you and Galantis agree that if you are a consumer resident of a jurisdiction other than those in (ii) below, the following governing law and arbitration provision applies:</motion.p>
                <motion.p variants={termsParagraph}>PLEASE READ THE FOLLOWING CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE DISPUTES WITH GALANTIS AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM GALANTIS.</motion.p>
                <motion.p variants={termsParagraph}>You and Galantis agree to arbitrate any dispute arising from these Terms of Site or your use of the Site, except that you and Galantis are not required to arbitrate any dispute in which either party seeks equitable and other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL. You and Galantis agree that you will notify each other in writing of any dispute within thirty (30) days of when it arises. Notice to Galantis shall be sent to: Galantis Limited c/o LaPolt Law P.C.  9000 Sunset Blvd., Suite 800, West Hollywood, CA 90069. You and Galantis further agree: to attempt informal resolution prior to any demand for arbitration; that any arbitration will occur in Los Angeles County, California; that arbitration will be conducted confidentially by a single arbitrator in accordance with the rules of JAMS; and that the state or federal courts in Los Angeles County, California have exclusive jurisdiction over any appeals of an arbitration award and over any suit between the parties not subject to arbitration. Other than class procedures and remedies discussed below, the arbitrator has the authority to grant any remedy that would otherwise be available in court. Any dispute between the parties will be governed by this Agreement and the laws of the State of California and applicable United States law, without giving effect to any conflict of laws principles that may provide for the application of the law of another jurisdiction. Whether the dispute is heard in arbitration or in court, you and Galantis will not commence against the other a class action, class arbitration, or other representative action or proceeding.</motion.p>
                <motion.p variants={termsParagraph}>(ii) If you are a resident in any jurisdiction in which the provision in the section above is found to be unenforceable, then any disputes, claims, or causes of action arising out of or in connection with these Terms of Site shall be resolved by competent civil courts in Los Angeles County, California.</motion.p>
                <motion.p variants={termsParagraph}><strong>e.  Claims</strong></motion.p>
                <motion.p variants={termsParagraph}>YOU AND GALANTIS AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SITES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</motion.p>
                <motion.p variants={termsParagraph}><strong>18. QUESTIONS</strong></motion.p>
                <motion.p variants={termsParagraph}>The Site is provided by Galantis Limited. If you have any questions, comments or complaints regarding this Agreement or the Site, feel free to contact us at <a href="contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}><strong>19. NOTICE FOR CALIFORNIA USERS</strong></motion.p>
                <motion.p variants={termsParagraph}>Under California Civil Code Section 1789.3, California Site users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</motion.p>
                <motion.p variants={termsParagraph}><strong>20. ACCESSIBILITY</strong></motion.p>
                <motion.p variants={termsParagraph}>Galantis strives to ensure that the Sites meet applicable accessibility requirements, which may include those pursuant to the Americans with Disabilities Act of 1990 and other applicable accessibility laws. Notwithstanding the foregoing, to the extent permitted by law, you acknowledge and agree that Galantis makes no warranties or guarantees with respect to its compliance with applicable accessibility laws. You agree to submit any questions and concerns regarding accessibility to Galantis in writing and allow for Galantis to have a commercially reasonable period of time to respond and address such items as necessary prior to instituting any legal proceedings against Galantis regarding accessibility. Questions and concerns regarding accessibility may be sent to <a href="contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}><strong>21. MISCELLANEOUS</strong></motion.p>
                <motion.p variants={termsParagraph}>If we fail to exercise or enforce any right or provision of these Terms, it will not constitute a waiver of such right or provision. Any waiver of any provision of these Terms will be effective only if in writing and signed by the relevant party. If any provision of these Terms is held to be unlawful, void, or for any reason unenforceable, then that provision will be limited or eliminated from these Terms to the minimum extent necessary and will not affect the validity and enforceability of any remaining provisions. These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Galantis without restriction. Any assignment attempted to be made in violation of this Terms shall be void. Upon termination of these Terms, any provision which, by its nature or express terms should survive, will survive such termination or expiration, including, but not limited to, Sections 7, 8, 10, 12, and 13-17. The Terms, which incorporate the Privacy Policy, is the entire agreement between you and Galantis relating to the subject matter herein and will not be modified except by a writing signed by authorized representatives of both parties, or by a change to these Terms made by Galantis as set forth in Section 6 above.</motion.p>
            </motion.div>
        </div>
    </motion.div>
</motion.div>

)
}

export default Terms 