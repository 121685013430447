import React, { useEffect, useState, useRef } from 'react'
import "../css/music-track.css"
import { AnimatePresence, motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const MusicTrack = (props) => {

const [openButton, setOpenButton] = useState(false)

const scrollUp = () => {
    setTimeout(() => 
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    }), 600)
}

const changeVideo = () => {
    props.setVideo(props.title.youtube)
    scrollUp()
}

const clicked = {
    open: { opacity: 1, transition: { duration: 0.3 } },
    closed: { opacity: 0 },
    exit: { opacity: 0, transition: { duration: 0.3 } }
}

let musicRef = useRef()

useEffect(() => {
    let handler = (e) => { 
        if(!musicRef.current.contains(e.target)){
            setOpenButton(false)
        }
    }
    document.addEventListener("mousedown", handler)
}, [openButton])

const { ref, inView } = useInView()
const animation = useAnimation()

useEffect(() => {
    if(inView){
        animation.start({  y: 0, opacity: 1, transition: { ease: "easeOut", duration: 0.3, delay: 0.1 }})
    }
    if(!inView){
        animation.start({ y: 20, opacity: 0, transition: { ease: "easeInOut", duration: 0.3 } })
    }
}, [inView])


  return (
    <>
    <motion.div ref={ ref } animate={ animation }>
        <motion.div layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="music-track-container" style={{ zIndex: 70 }}>
            <div className='music-track-image'>
                <img src={props.title.imageUrl} width={ "100%" } />
            </div>
            <div className='music-track-center'>
                <div className="music-track-artist">{ props.title.artist }</div>
                <div className="music-track-name"> { props.title.title }</div>
            </div>
            <div>
                <div className='music-track-arrow'>
                    <svg width="22" height="22">
                        <line x1="18" y1="2" x2="2" y2="18" stroke="white" />
                        <line x1="18" y1="2" x2="18" y2="18" stroke="white" />
                        <line x1="2" y1="2" x2="18" y2="2" stroke="white" />
                    </svg>
                </div>
                <div className='music-track-button-container'>
                    {props.title.youtube ? <div className="music-track-button" onClick={changeVideo}>YouTube<motion.div whileHover={{ opacity: 1}} transition={{ duration: 0.3 }} className="music-track-overlay"></motion.div></div> : <div className="empty-square" />}
                <a style={{ textDecoration: "none" }} className="music-track-button" href={props.title.spotify} target="_blank">Spotify<motion.div whileHover={{ opacity: 1}} transition={{ duration: 0.3 }} className="music-track-overlay"></motion.div></a>
                <a style={{ textDecoration: "none" }} className="music-track-button" href={props.title.apple} target="_blank">Apple<br />Music<motion.div whileHover={{ opacity: 1}} transition={{ duration: 0.3 }} className="music-track-overlay"></motion.div></a>
                </div>
            </div>
        </motion.div>
    </motion.div>
    <motion.div onClick={() => setOpenButton(true) } className="mobile-button"></motion.div>
    

        <motion.div className='mobile-shell' ref={musicRef} > 
            <AnimatePresence>
            { openButton && (
                    <motion.div variants={ clicked } initial={{ opacity: 0 }} animate={ openButton ? "open" : "closed"} className="mobile-cover-overlay">
                        <motion.div className="mobile1"><motion.div  className="mobile-inner"></motion.div></motion.div>
                    </motion.div>
                        )}
            </AnimatePresence>
            <AnimatePresence>
            { openButton && (
                <motion.div variants={ clicked } initial={{ opacity: 0 }} animate={ openButton ? "open" : "closed"} className="mobile-button-container"><div className='mobile2'></div>
                {props.title.youtube ? <div className="mobile3" onClick={changeVideo}>YouTube</div> : <div className="empty-square" />}
                <a style={{ textDecoration: "none" }} href={props.title.spotify} target="_blank" className='mobile3'>Spotify</a>
                <a style={{ textDecoration: "none" }} href={props.title.apple} target="_blank" className='mobile3'>Apple<br />Music</a></motion.div> 
            )}
            </AnimatePresence>
        </motion.div>
    </>
  )
}

export default MusicTrack