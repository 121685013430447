import React from 'react'
import "../css/scroll-down.css"
import { motion, useScroll, useTransform } from "framer-motion"

const ScrollDown = (props) => {

    let x = useTransform(props.scrollY, [0, 600], [0, -300])

  return (
        <div>
            <div className="scroll-down-container">
                <motion.div style={{ x }} className="scroll-down-grid">
                    <div className='down-arrow'>
                        <motion.svg animate={{x: [0, -8, 0]}} transition={{ repeat: Infinity, duration: 1 }} width="20" height="20">
                            <line x1="0" y1="10" x2="20" y2="10" stroke="white" />
                            <line x1="0" y1="10" x2="5" y2="15" stroke="white" />
                            <line x1="0" y1="10" x2="5" y2="5" stroke="white" />
                        </motion.svg>
                    </div>
                    <div className='scroll-down'>SCROLL</div>
                </motion.div>
            </div>
        </div>
    )
    }

export default ScrollDown