import React, { useEffect, useContext } from 'react'
import "../css/copyright.css"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import { ScrollContext } from '../helpers/ScrollContext'
import { TermsContext } from '../helpers/TermsContext'
import { PrivacyContext } from '../helpers/PrivacyContext'

const Copyright = () => {

const { scroller, setScroller } = useContext(ScrollContext)
const { terms, showTerms } = useContext(TermsContext)
const { privacy, showPrivacy } = useContext(PrivacyContext)

const combinedTerms = () => {
  showTerms(true)
  setScroller(true)
}

const combinedPrivacy = () => {
  showPrivacy(true)
  setScroller(true)
}


const d = new Date();
let year = d.getFullYear();

const [ ref2, inView2 ] = useInView()

const cookieIn = useAnimation()
const privacyIn = useAnimation()
const termsIn = useAnimation()
const copyrightIn = useAnimation()

useEffect(() => {
  if(inView2){
      cookieIn.start({  opacity: 1, y: 0, transition: { duration: 0.7, delay: 0.4 }})
      privacyIn.start({ opacity: 1, transition: { duration: 0.7, delay: 0.6 } })
      termsIn.start({  opacity: 1, transition: {duration: 0.7, delay: 0.8 } })

  }
  if(!inView2){
      cookieIn.start({ opacity: 0, transition: { duration: 0 } })
      privacyIn.start({ opacity: 0, transition: { duration: 0 } })
      termsIn.start({ opacity: 0, transition: { duration: 0 } })
  }
}, [inView2])


  return (
    <>
    <div ref={ref2} className="copyright">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <motion.div onClick={() => combinedPrivacy()} style={{cursor: "pointer", marginRight: 10}} animate={ privacyIn } whileHover={{ color: "#ddd", transition: { duration: 0.5 } }}>Privacy Policy</motion.div> | <motion.div style={{ cursor: "pointer", marginLeft: 10}} animate={ termsIn } onClick={() => combinedTerms()} whileHover={{ color: "#ddd", transition: { duration: 0.5 } }}>Terms & Conditions</motion.div>
      </div>
      <motion.div className="copy2">
          &copy; Copyright Galantis { year } | Website by <motion.a style={{ textDecoration: "none" }} whileHover={{ letterSpacing: "0.2em", color: "#ddd" }} transition={{ duration: 0.5 }} className='li1' href='https://manta.studio' target="_blank"><strong>MANTA</strong></motion.a>
      </motion.div>
    </div>
    </>
  )
}

export default Copyright