import React, { useRef, useEffect, useState } from 'react'
import "../css/galantis.css"
import { motion, useScroll, useTransform, useAnimation } from "framer-motion"
import BandImg from "../assets/bangbangpress.jpg"
import { useInView } from "react-intersection-observer"
import { useNavigate } from 'react-router-dom'

const Galantis = ({ overlay, setOverlay }) => {

const { ref, inView } = useInView()
const targetRef = useRef()
const [ about, setAbout ] = useState()

const navigate = useNavigate()

const overlayTrigger = async () => {
    setOverlay(true)
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        setTimeout(() => {
            navigate("/about")
          }, 1000)
      }, 500)
    setTimeout(() => {
        setOverlay(false)
      }, 2000)
 } 


const { scrollYProgress } = useScroll({ 
    target: targetRef,
    offset: ["start start", "end start"]
})

const pathLength = useTransform(scrollYProgress, [0, 0.2, 1], [0, 1, 1])
const fillOpacity = useTransform(scrollYProgress, [0.05, 0.2, 1], [0, 1, 1])
const y = useTransform(scrollYProgress, [0, 1], [-175, 0])
const opacity = useTransform(scrollYProgress, [0, 0.1, 1], [0, 1, 1])
const scale = useTransform(scrollYProgress, [0, 1], [1, 0.8])
const what = useTransform(scrollYProgress, [0, 0.35, 0.6], [1, 1, 0])


  return (
    <>
    <motion.div style={{ opacity }} className='galantis-container' ref={targetRef}>
        <div className="galantis-large">
            <motion.svg style={{ fill: "#ddd", pointerEvents: "none", scale, opacity: what }} className="galantis-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXLink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 200 50" xmlSpace="preserve">
                    <g>
                        <motion.path style={{ pathLength, fillOpacity }} className="g1" stroke="#ddd" strokeWidth="0.3px" d="M23.7,38.1c-0.7,0.2-1.4,0.4-1.9,0.5c-0.7,0.1-1.3,0.2-1.7,0.2c-1.4,0-3-0.4-4.5-1.1c-1.6-0.7-3.1-1.8-4.3-3.1
                            l0,0l0,0c-2.3-2.4-3.6-5.7-3.6-9.3c0-4.4,2.1-8.4,5.5-10.8c2.4-1.7,5.1-2.6,8-2.6c1.7,0,3.3,0.4,5,1.2l1,0.5V6.8l-0.5-0.1
                            c-1.8-0.5-3.6-0.8-5.3-0.8c-3,0-5.8,0.5-8.3,1.5c-2.5,1-4.7,2.4-6.6,4.3l0,0c-3.6,3.6-5.7,8.6-5.7,13.7c0,5.1,2,10.1,5.7,13.7
                            c1.8,1.8,3.9,3.2,6.1,4.2c2.3,0.9,4.8,1.4,7.4,1.4c3.7,0,6.9-0.9,9.7-2.8l0.3-0.2V24.7h-6.4V38.1z"/>
                        <motion.path style={{ pathLength, fillOpacity }} className="empty" stroke="#f00" strokeWidth="0.3px" d="M6.5,11.7L6.5,11.7L6.5,11.7z"/>
                        <motion.polygon style={{ pathLength, fillOpacity }} className="empty" stroke="#f00" strokeWidth="0.3px" points="6.5,39 6.5,39 6.5,39 	"/>
                        <motion.polygon style={{ pathLength, fillOpacity }} className="l1" stroke="#ddd" strokeWidth="0.3px" points="68.4,6.7 61.7,6.7 61.7,7.3 61.7,43.9 79.7,43.9 79.7,37.2 68.4,37.2 	"/>
                        <motion.polygon style={{ pathLength, fillOpacity }} className="n1" stroke="#ddd" strokeWidth="0.3px" points="132.5,29.4 111.8,6.2 111.8,8 111.8,43.9 118.5,43.9 118.5,23.6 139.2,44.7 139.2,6.7 132.5,6.7 	"/>
                        <motion.polygon style={{ pathLength, fillOpacity }} className="t1" stroke="#ddd" strokeWidth="0.3px" points="141.3,13.3 149.7,13.3 149.7,43.9 156.7,43.9 156.7,13.3 165,13.3 165,6.7 141.3,6.7 	"/>
                        <motion.polygon style={{ pathLength, fillOpacity }} className="i1" stroke="#ddd" strokeWidth="0.3px" points="166.9,7.3 166.9,43.9 173.6,43.9 173.6,6.7 166.9,6.7 	"/>
                        <motion.path style={{ pathLength, fillOpacity }} className="s1" stroke="#ddd" strokeWidth="0.3px" d="M198.5,28.1c-0.3-0.9-0.8-1.7-1.3-2.5c-0.8-1.1-2-2.1-3.3-2.9c-1.3-0.8-2.9-1.6-4.8-2.3h0h0
                        c-1.8-0.7-3.2-1.4-4.1-2.1c-0.4-0.4-0.8-0.8-1-1.2s-0.3-0.9-0.3-1.5c0-0.9,0.4-1.7,1-2.3c0.6-0.6,1.5-1,2.6-1
                        c0.9,0,1.7,0.3,2.3,0.8c0.6,0.5,1.1,1.3,1.4,2.3l0.2,0.8l6-2.5l-0.2-0.6c-0.8-2.3-2.1-4.1-3.8-5.3c-1.7-1.2-3.8-1.9-6.1-1.9
                        c-2.7,0-5.2,1.1-7,2.9c-1.8,1.8-3,4.3-3,7.1c0,1.3,0.3,2.6,0.9,3.8c0.6,1.3,1.3,2.5,2.3,3.4c1,0.9,2,1.6,3,2.1
                        c1,0.5,2.1,0.8,3.1,1.2h0h0c1.1,0.4,2,0.8,2.8,1.1c0.6,0.3,1.1,0.6,1.5,0.9c0.6,0.5,1,1,1.3,1.6c0.3,0.6,0.4,1.4,0.4,2.4
                        c0,1.6-0.5,3-1.4,3.9c-0.5,0.5-1,0.8-1.7,1.1s-1.4,0.4-2.3,0.4c-0.8,0-1.4-0.1-2-0.4c-0.9-0.3-1.6-0.9-2.2-1.8s-1-2.1-1.3-3.6
                        l-0.1-0.9l-0.8,0.4l-5.4,2.3l0.1,0.5c0.6,3.1,2.1,5.6,4.2,7.4c2.1,1.7,4.7,2.7,7.7,2.7c3.3,0,6.3-1.3,8.4-3.5
                        c2.2-2.2,3.5-5.3,3.5-8.9C199.2,30.7,199,29.3,198.5,28.1z"/>
                        <motion.path style={{ pathLength, fillOpacity }} className="a1" stroke="#ddd" strokeWidth="0.3px" d="M31.1,43.9h6.8l2.6-7.1h10.5l2.6,7.1h0.5h6.2L46.2,6.4L31.1,43.9z M49,30.3h-6l3.2-8.1L49,30.3z"/>
                        <motion.path style={{ pathLength, fillOpacity }} className="a2" stroke="#ddd" strokeWidth="0.3px" d="M81.1,43.9h6.8l2.6-7.1h10.5l2.6,7.1h0.5h6.2L96.2,6.4L81.1,43.9z M98.9,30.3h-6l3.2-8.1L98.9,30.3z"/>
                    </g>
            </motion.svg>
            
        </div>
        <div className="galantis-band-image">
            <motion.div className="band-image" style={{ backgroundColor: "blue" }}>
            <motion.a onMouseEnter={() => setAbout(true)}  onMouseLeave={() => setAbout(false)} href="#" onClick={overlayTrigger} animate={ about ? { opacity: 0.7 } : { opacity: 1 }} style={{ position: "absolute", zIndex: 3000, cursor: "pointer", top: "-50px", textDecoration: "underline solid 1px", textUnderlineOffset: "10px", fontSize: "clamp(0.8rem, 1vw, 1.2rem)" }}>About Galantis...</motion.a>
                <div style={{ overflow: "hidden", height: "100%" }}>
                <motion.div initial={{ opacity: 0 }} animate={about ? {opacity: 1} : {opacity: 0}} transition={{ ease: [0.5, 0.7, 0.05, 0.9], duration: 0.5 }}  onClick={overlayTrigger} onMouseEnter={() => setAbout(true)} onMouseLeave={() => setAbout(false)}  style={{ position: "absolute", cursor: "pointer", width: "100%", height: "100%", backgroundColor: "#09090999", zIndex: 500, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <motion.div initial={{ rotate: 0 }} animate={about ? {rotate: "45deg"} : {rotate: 0}} transition={{ ease: [0.5, 0.7, 0.05, 0.9], duration: 0.5 }} ><svg width="50" height="50">
                        <line x1="48" y1="2" x2="2" y2="48" stroke="white" />
                        <line x1="48" y1="2" x2="48" y2="48" stroke="white" />
                        <line x1="2" y1="2" x2="48" y2="2" stroke="white" />
                    </svg>
                </motion.div>
                </motion.div>
            <motion.img animate={about ? {scale: 1.05} : {scale: 1}} ref={ targetRef } className="inner-image" style={{ y, cursor: "pointer" }} transition={{ duration: 0.5, ease: "easeOut" }} src={BandImg} alt="bandimage" />
                </div>
            </motion.div>
        </div>
    
    </motion.div>
    </>
  )
}

export default Galantis