import React, { useContext } from 'react'
import "../css/subscribe.css"
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import * as Yup from "yup"
import Label from './Label'
import axios from 'axios'
import { motion } from "framer-motion"
import { ScrollContext } from '../helpers/ScrollContext'
import { TermsContext } from '../helpers/TermsContext'
import { PrivacyContext } from '../helpers/PrivacyContext'


interface FormValues {
    firstName: string,
    lastName: string,
    email: string,
    city: string,
    country: string
}


const SignUpSchema = Yup.object().shape({
    firstName: Yup.string().max(30, "maximum 30 characters").required("Required"),
    lastName: Yup.string().max(30, "maximum 30 characters"),
    email: Yup.string().email("Invalid email address").required("Required"),
    city: Yup.string().max(30, "maximum 30 characters"),
    country: Yup.string().required("Required"),
})


const Subscribe = () => {

const { scroller, setScroller } = useContext(ScrollContext)
const { terms, showTerms } = useContext(TermsContext)
const { privacy, showPrivacy } = useContext(PrivacyContext)

const combinedTerms = () => {
  showTerms(true)
  setScroller(true)
}

const combinedPrivacy = () => {
  showPrivacy(true)
  setScroller(true)
}

    const submitForm = async (values: FormValues, formik: FormikHelpers<FormValues> ) => {
        const {firstName, lastName, email, city, country} = values;
        try {
            const payload = {
                email_address: email,
                merge_fields: {
                    FNAME: firstName,
                    LNAME: lastName,
                    MMERGE4: city,
                    MMERGE29: country,
                },
            }
            console.log(payload)
            await axios.post("/.netlify/functions/signup", payload)
            alert("Contact details were added successfully")
            formik.resetForm()
        }
        catch (error) {
            if (error.response.data.status === 400 )
            alert('Error - Does member already exist?')

            else 
            alert(error.response.data.status)
            console.log(error) 
        }
    }

  return (

    <div className='subscribe-container'>
        <Formik initialValues={{ firstName: "", lastName: "", email: "", city: "", country: ""  }}
        validationSchema={SignUpSchema}
        onSubmit={submitForm}
        >
            {(formik) => (
            <div className="form-container">
                <div className="subscribe-container-spacer"></div>
                    <Form className='form-actual'>
                        <div style={{overflow: "hidden"}}>
                            <motion.div initial={{ y: 55 }} animate={{ y: 0, transition: { delay: 2, duration: 1, ease: [0.5, 0.1, -0.05, 0.9] } }}>
                                <div className="sign-up-title">SIGN UP</div> 
                            </motion.div>
                        </div><br />
                        <motion.div className="label-mask">
                            <motion.div className="labels" initial={{ y: 50 }} animate={{ y: 0, transition: { delay: 2.2, duration: 1 } }}>
                                <Label htmlFor="firstName" text='First Name' required={true} /><ErrorMessage name='firstName' />
                            </motion.div>
                        </motion.div>
                        <motion.div initial={{ width: 0, opacity: 0 }} animate={{ width: "100%", opacity: 1, transition: { delay: 1, duration: 1 } }}>
                            <Field id="firstName" className="first-name" name="firstName"></Field>
                        </motion.div>
                        <motion.div className="label-mask">
                            <motion.div className="labels" initial={{ y: 50 }} animate={{ y: 0, transition: { delay: 2.4, duration: 1 } }}>
                                <Label htmlFor="lastName" text='Last Name' required={false} /><ErrorMessage name='lastName' />
                            </motion.div>
                        </motion.div>
                        <motion.div initial={{ width: 0,  opacity: 0 }} animate={{ width: "100%",  opacity: 1, transition: { delay: 1.2, duration: 1 } }}>
                            <Field id="lastName" className="last-name" name="lastName"></Field> 
                        </motion.div>
                        <motion.div className="label-mask">
                            <motion.div className="labels" initial={{ y: 50 }} animate={{ y: 0, transition: { delay: 2.6, duration: 1 } }}>
                                <Label htmlFor="email" text='Email' required={true} /><ErrorMessage name='email' />
                            </motion.div>
                        </motion.div>
                        <motion.div initial={{ width: 0,  opacity: 0 }} animate={{ width: "100%",  opacity: 1, transition: { delay: 1.4, duration: 1 } }}>
                            <Field id="email" className="email" name="email"></Field>
                        </motion.div>
                        <motion.div className="label-mask">
                            <motion.div className="labels" initial={{ y: 50 }} animate={{ y: 0, transition: { delay: 2.8, duration: 1 } }}>
                                <Label htmlFor="city" text='City' required={false} /><ErrorMessage name='city' />
                            </motion.div>
                        </motion.div>
                        <motion.div initial={{ width: 0,  opacity: 0 }} animate={{ width: "100%",  opacity: 1, transition: { delay: 1.6, duration: 1 } }}>
                            <Field id="city" className="city" name="city"></Field>
                        </motion.div>
                        <motion.div className="label-mask">
                            <motion.div className="labels" initial={{ y: 50 }} animate={{ y: 0, transition: { delay: 3, duration: 1 } }}>
                                <Label htmlFor="country" text='Country' required={true} /><ErrorMessage name='country' />                                
                            </motion.div>
                        </motion.div>
                        <motion.div initial={{ width: 0,  opacity: 0 }} animate={{ width: "100%",  opacity: 1, transition: { delay: 1.8, duration: 1 } }}>
                            
                            <Field id="country" className="country" as="select" name="country">
                            <option>Choose option*</option>
                            <option value="United States">United States</option>
                            <option value="Aaland Islands">Aaland Islands</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antigua And Barbuda">Antigua And Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire, Saint Eustatius and Saba">Bonaire, Saint Eustatius and Saba</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curacao">Curacao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">Falkland Islands</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guinea">French Guinea</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey  (Channel Islands)">Jersey  (Channel Islands)</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="North Korea">North Korea</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Republic of Kosovo">Republic of Kosovo</option>
                            <option value="Reuinion">Reuinion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Martin">Saint Martin</option>
                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                            <option value="Samoa (Independent)">Samoa (Independent)</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Sint Maarten">Sint Maarten</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                            <option value="South Korea">South Korea</option>
                            <option value="South Sudan">South Sudan</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="St. Helena">St. Helena</option>
                            <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-Leste">Timor-Leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks & Caicos Islands">Turks & Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="USA Minor Outlying Islands">USA Minor Outlying Islands</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City State (Holy See)">Vatican City State (Holy See)</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                            <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                            <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                            </Field>
                        </motion.div>
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.5, duration: 1 } }}>
                        <motion.button disabled={!formik.isValid || !formik.dirty}
                        className="subscribe-button" type='submit'>Subscribe</motion.button>
                        <div className='subscribe-subtext'>By submitting your details on this subscription form, you are agreeing to the terms outlined in our&nbsp;
                        <motion.span whileHover={{ color: "#6b21b4" }} onClick={() => combinedTerms()} style={{ fontWeight: 600, cursor: "pointer" }}>Terms & Conditions</motion.span> and <motion.span onClick={() => combinedPrivacy()} whileHover={{ color: "#6b21b4" }} style={{ fontWeight: 600, cursor: "pointer" }}>Privacy Policy</motion.span></div>
                        
                        </motion.div>
                    </Form>
                <div className="subscribe-container-spacer"></div>    
            </div>
            )}
        </Formik>
    </div>

  )
}

export default Subscribe