import React, { useRef, useState, useEffect } from 'react'
import { motion, useScroll, useTransform } from "framer-motion"
import liveOne from "../assets/hxphy.jpg"
import "../css/tour.css"
import "../css/tour-section.css"
import Giglist from './Giglist'
import Footer from './Footer'

const Tour = ({ windowWidth }) => {

    const [data, setData] = useState();
    const [gig, setGig] = useState([])

    const [gigList, setGigList] = useState([]);

    const fetchData = async () => {
    try {
    // const response = await fetch('https://rest.bandsintown.com/artists/Galantis/events/?app_id=42f39cee7f4bda73a6b7dceb382bcc56'); 
    const response = await fetch('/.netlify/functions/tourdates'); 
    if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    setGigList(data);
    } catch (error) {
    console.error("Fetch error:", error);
    }
    };


    useEffect(() => {
        fetchData()
    }, [])

    console.log(gigList)

    const targetRef = useRef()

    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start start", "end start"]
    })
    
    const y = useTransform(scrollYProgress, [0, 1], [0, -200])
    const whatY = useTransform(scrollYProgress, [0, 1], [0, 200])

    let title = {
        closed: { opacity: 1 },
        open: { opacity: 1, transition: { delayChildren: 1, staggerChildren: 0.1 }}
    }

    let letters = {
        closed: { y: "24vw" },
        open: { y: 0, transition: { duration: 1, ease: [0.5, 0.7, 0.05, 1] } }
    }



  return (
    <>
    <div className='tour-header'>
        <div className="black-overlay"></div>
        <motion.div style={{ zIndex: 200, y }}>
            <motion.div className="twotwo">
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.5, duration: 1 }}}>0</motion.div>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.7, duration: 1 }}}>2</motion.div>
            </motion.div>
            <motion.div className="tour-title" variants={ title } initial="closed" animate="open">
                <motion.div variants={ letters }>T</motion.div>
                <motion.div variants={ letters }>O</motion.div>
                <motion.div variants={ letters }>U</motion.div>
                <motion.div variants={ letters }>R</motion.div>
            </motion.div>
        </motion.div>
        <motion.img src={liveOne} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.5, duration: 1 }}} width="100%" height="100%" style={{ objectFit: "cover", position: "absolute", top: 0, zIndex: 1, y: whatY }} /> 
    </div>
    <div className="giglist-container">
        { gigList.data?.map((gigs => { 
            // { gigList.map((gigs => { 
                return (
                    <>
                    <Giglist 
                    // key={gigs.venue.name}
                    // country={gigs.venue.country}
                    // city={gigs.venue.city}
                    // date1={gigs.datetime?.slice(8, 10)}
                    // date2={gigs.datetime?.slice(5, 7)}
                    // event={gigs.venue.name}
                    // tickets={gigs.url}
                    key={gigs.attributes["venue-name"]} 
                    country={gigs.attributes["venue-country"]} 
                    city={gigs.attributes["venue-city"]} 
                    date1={gigs.attributes["starts-at-date-local"]?.slice(8, 10)} 
                    date3={gigs.attributes["ends-at-date-local"]?.slice(8, 10)} 
                    date2={gigs.attributes["starts-at-date-local"]?.slice(5, 7)} 
                    event={gigs.attributes["venue-name"]} 
                    tickets={gigs.attributes["primary-link-url"]} 
                    />  
                    </>
                )}))
            }
    </div>
    <Footer windowWidth={ windowWidth } />
    </>
  )
}

export default Tour