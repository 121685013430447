import React from "react";

const Label = (props: {
    text: string,
    htmlFor: string,
    required?: boolean
}) => {

    const { text, required, htmlFor } = props

    return (
        <>
            <label htmlFor={htmlFor}>
                 { text }
                 { required && <span className="text-red">*</span>}
            </label>
        </>
    )
}

export default Label