import React from "react"
import "../css/terms.css"
import { motion } from "framer-motion"
import { PrivacyContext } from "../helpers/PrivacyContext"
import { useContext } from "react"
import { ScrollContext } from "../helpers/ScrollContext"

const Privacy = ({ windowWidth }) => {

const { privacy, showPrivacy } = useContext(PrivacyContext)
const { scroller, setScroller } = useContext(ScrollContext)

const closeTerms = () => {
    showPrivacy(false)
    setScroller(false)
}

const termsParent = {
    closed: { opacity: 1 },
    open: { opacity: 1, transition: { delayChildren: 0.7, staggerChildren: 0.15 }}
}

const termsParagraph = {
    closed: { y: 5, opacity: 0 },
    open: { y: 0, opacity: 1, transition: { duration: 0.9 } }
}


return (

<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} className="shade-layer">
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3, duration: 0.6 } }} className="terms-frame">
        <motion.div style={ windowWidth < 700 ? { visibility: "hidden"} : { visibility: "visible" }} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.5, duration: 0.6 } }} className="terms-title">Privacy Policy</motion.div>
        <motion.div whileHover={{ rotate: 180, transition: { ease: "linear"} }} onClick={() => {closeTerms()}} className="terms-cross">
            <div className="terms-cross-1"></div>
            <div className="terms-cross-2"></div>
        </motion.div>
        <div className="middle-box">
            <motion.div variants={termsParent} initial="closed" animate="open" className="terms-text">
                <motion.p variants={termsParagraph}>We are working hard to make sure that we comply with the European Union’s General Data Protection Regulation (“GDPR”).  We value your privacy and your rights to own and control your personal data. We will never sell your data to third parties.</motion.p>
                <motion.p variants={termsParagraph}>The data that we do collect is meant to improve your user experience.</motion.p>
                <motion.p variants={termsParagraph}>Unless otherwise stated, Galantis Limited (“Galantis”) are data controllers for the personal data we collect through Galantis’s website (the “Site”), emails, and third-party applications where applicable (herein collectively referred to as “Services”) subject to this Privacy Policy (“Policy”) and under applicable data protection laws.</motion.p>
                <motion.p variants={termsParagraph}>ATTENTION: PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THE SERVICES. USING THE SERVICES INDICATES THAT YOU ACCEPT AND UNDERSTAND THIS PRIVACY POLICY IN FULL. IF YOU DO NOT ACCEPT THIS PRIVACY POLICY, DO NOT USE THE SERVICES.</motion.p>
                <motion.p variants={termsParagraph}>Each of the Galantis entities, now in existence or hereafter created, separately make the disclosures in this Policy.  The data protection officer of Galantis can be contacted at the following email address: <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}>This Policy explains what personal information we collect from you, either directly or indirectly, and how we use it.  Please read this Policy carefully to understand how Galantis (also referred to herein as “we”, “us”, and “our”) may collect, use, and share your personal information. </motion.p>
                <motion.p variants={termsParagraph}>This Policy does not apply to any data insofar as it is held, processed, disclosed or published in a form which cannot be linked to a living individual (such as anonymized data, aggregated data, or coded data which, in a given form, cannot effectively be used to extract your personal data) ("Anonymized and Aggregated Data"). We reserve the right to generate Anonymized and Aggregated Data extracted out of any databases containing your personal data and to make use of any such Anonymized and Aggregated Data as we see fit.</motion.p>
                <motion.p variants={termsParagraph}>Please review the Privacy Policy each time you use the Service. BY USING THE SERVICE, YOU ACKNOWLEDGE AND ACCEPT THE MOST RECENT VERSION OF THE PRIVACY POLICY.</motion.p>
                <motion.p variants={termsParagraph}><strong>INFORMATION WE MAY COLLECT</strong><br />We automatically collect information about you, your use of our website, or information collected by using cookies and similar technologies when you use, access or interact with our website. We may, for example, collect information about the type of device you use to access our websites, the operating system and version, your IP address, your general geographic location as indicated by your IP address, your browser type, the webpages you view on our websites, and whether and how you interact with content available on our websites.</motion.p>
                <motion.p variants={termsParagraph}><strong>Information Obtained from Other Sources.</strong><br />We may receive information about you from other sources, including third parties, such as music service partners and partners with whom we offer co-branded services or engage in joint marketing activities. We may also receive information about you from social media platforms. We protect data obtained from third parties according to the practices described in this Policy and we also apply any additional restrictions imposed by the source of data.</motion.p>
                <motion.p variants={termsParagraph}><strong>For Your Reference, We May Collect the Following Data:</strong><br /><br />
                <ul>
                    <li>Forms filled out by you including site registrations (where applicable)</li>
                    <li>Subscriptions or orders placed on the Site;</li>
                    <li>Participation in discussion boards or other social media functions on the Site;</li>
                    <li>Entering competitions, promotions, or surveys;</li>
                    <li>Correspondence with us by phone, e-mail, or otherwise;</li>
                    <li>Reporting a problem or requesting support for the Site</li>
                    <li>Using features made available</li>
                    <li>Signing up for news and updates</li>
                    <li>Providing information to us in relation to a product, service or technical issue</li>
                </ul></motion.p>
                <motion.p variants={termsParagraph}>The customer interactions with Galantis are broken down into the following segments:</motion.p>
                <motion.p variants={termsParagraph}><strong>Basic User Data.</strong><br />This includes name, email address, IP address, and information provided by cookies or similar technology to share news about events and products offered by Galantis. The IP address helps us to understand geographic information about our websites’ visitors to better improve our website. It is our legitimate interest to process your personal data for these purposes.</motion.p>
                <motion.p variants={termsParagraph}><strong>Purchasing Data.</strong><br />We use third party websites to sell Galantis products, including Galantis or Galantis’s artist branded merchandise (“Galantis Products”). By clicking the link to the merch store in your region, you acknowledge that you will be subject to such third party’s privacy policy and terms of service.  By continuing to use such third party site, you acknowledge you have reviewed and agreed to such privacy policy and terms of service. To the extent there is no privacy policy on such third party site, the terms contained herein with respect to our use of any data we receive from such third party site shall apply. You acknowledge that these terms only apply to our use of such data and we make no representations or warranties regarding the use of any data by any third party site.</motion.p>
                <motion.p variants={termsParagraph}>To effectuate such transactions, we may collect: your name, phone number (optional), email address, delivery/shipping address, and payment information.  This information is used solely to complete your transaction, follow up with you about your purchase, help with any delivery issues, handle returns, and other issues related to the purchase of your Galantis Products. <strong>We do not store any payment or credit card information. We only use payment information in connection with the purchase of Galantis Products.</strong></motion.p>
                <motion.p variants={termsParagraph}><strong>We may collect:</strong><br />your name, phone number, email address, location data, your password and IP address. Please note, your password is stored using one-way hash, which means that it cannot be recovered (or disclosed) by anyone, including Galantis.</motion.p>
                <motion.p variants={termsParagraph}><strong>Why:</strong><br />We collect this information to help get your Galantis services processing correctly.  We also use your email to send you Galantis-related notifications, such as promotional messages, information about Galantis Products and/or Services, and to share exciting Galantis news and Product offerings. It is our legitimate interest to process your personal data for these purposes.</motion.p>
                <motion.p variants={termsParagraph}><strong>Registration Data.</strong><br />This data includes your email address, location, language preference, Product serial number, IP address, and Galantis account login information (as described above).</motion.p>
                <motion.p variants={termsParagraph}><strong>Device Identifiers.</strong><br />When you access or use the Services using a mobile device, we may access, collect, monitor and/or remotely store one or more "device identifiers," such as a universally unique identifier. Device identifiers are small data files or similar data structures stored on or associated with your device that uniquely identify your device. A device identifier may consist of data stored in connection with the device hardware, operating system or other software, or data sent to the device by us. A device identifier may convey information to us about how you browse and use the Services. A device identifier may remain persistently on your device to enhance your navigation on the Services. Some features of the Services may not function properly if use or availability of device identifiers is impaired or disabled.</motion.p>
                <motion.p variants={termsParagraph}><strong>Advertising Identifiers.</strong><br />Advertising identifiers are unique strings associated with your mobile device that are provided by the operating system. Certain advertising identifiers may be modified or disabled by users in the operating system's settings.</motion.p>
                <motion.p variants={termsParagraph}><strong>HOW WE USE PERSONAL INFORMATION</strong><br />Galantis uses the data we collect to provide you with the Galantis Products and Services we offer, which includes using data to improve and personalize your experiences. We also use the data to communicate with you, for example, informing you about your account, new Galantis Products or Services available, security and other types of updates.</motion.p>
                <motion.p variants={termsParagraph}><strong>Galantis Uses the Data for The Following Purposes:</strong></motion.p>
                <ul>
                    <li>We use data to respond to customer inquiries, diagnose Product problems, and provide other customer care and support services. This processing is necessary to serve our legitimate interest.</li>
                    <li>We continually use data for research purposes and to develop and improve our Galantis Products and Services, including maintaining and improving the performance of our Galantis Services, using search queries and clicks to improve the relevance of search results and using usage data to determine what new features to prioritize. This processing is necessary to serve our legitimate interest.</li>
                    <li>We use data to protect the security and safety of our Galantis Products and our customers, to detect and prevent fraud, to resolve disputes and enforce our agreements. This processing is necessary to serve our legitimate interest.</li>
                    <li>We use data to develop aggregate analysis and business intelligence that enable us to operate, protect, make informed decisions, and report on the performance of our business. This processing is necessary to serve our legitimate interest.</li>
                    <li>Where you sign up for the Services through an account with a third party content or platform provider, we may use data to report to that third party and to communicate with it in relation to your activities on the platform or in relation to the content.</li>
                    <li>We use the data we collect to deliver and personalize our communications with you. For example, we may contact you by email or other means of electronic communication Galantis to inform you about new Galantis Products or Services, and security updates, to update you on a support issue, to invite you to take part in a survey, or to send you Services-related notices (e.g., account verification, technical notices). This processing is necessary to serve our legitimate interest.</li>
                    <li>We also may use your data as follows:
                        <ul>
                            <li>Give you access to the Site and it features;</li>
                            <li>Provide you with the services and information you request from us;</li>
                            <li>Verify accounts and their activity;</li>
                            <li>Complete transactions;</li>
                            <li>Monitor and improve the functionality of the Site including consistency of your experience across various devices;</li>
                            <li>Contact you, from time to time, about changes, enhancements or other similar notices regarding the Sites;</li>
                            <li>Administer contests and other promotions;</li>
                            <li>Complete administrative functions, such as to reply when you contact us about inquiries, complaints, or suggestions;</li>
                            <li>Learn more about, better serve, and cater our Site to your preferences so we can do things like suggest articles, events and other things that we think you might like;</li>
                            <li>Send you e-communications about offers and information about us and/or selected partners that we feel will be of interest to you, if you have indicated you want to receive such offers and information;</li>
                            <li>Perform statistical analyses of the collective characteristics of users of the Site, to measure user demographics and interests, to describe our services to third parties such as prospective business partners and advertisers and to analyze how and where best to use our resources; and</li>
                            <li>Text marketing (if applicable): With your permission, we may send text messages about our store, new products and other updates. Updates include checkout reminders. Webhooks will be used to trigger the Checkout Reminders messaging system.</li>
                        </ul>
                    </li>
                </ul>
                <motion.p variants={termsParagraph}><strong>Children.</strong><br />This Site is not directed toward children nor does Galantis knowingly collect information from children without parental consent except where in compliance with applicable laws.  If, following a notification by a parent or guardian or upon discovery, a child under sixteen has been improperly registered, we will delete the child's personal information from our records.</motion.p>
                <motion.p variants={termsParagraph}><strong>HOW WE MAY SHARE PERSONAL INFORMATION</strong><br />Galantis uses a variety of third-party vendors to carry out services like websites management and hosting, online Product purchases and shipping, credit card processing and email communications. </motion.p>
                <motion.p variants={termsParagraph}>We only share your personal data as necessary, such as to complete a transaction or to provide a Product or Service you have requested or authorized and only with vendors or agents working on our behalf for the purposes described in this Policy.  It is our legitimate interest to share information with these parties for these purposes. For questions about third-party vendors, please send an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}>When legally required, strictly necessary for the performance of the services or to protect our rights, or the rights of our affiliates or users, we disclose your personal information to law enforcement authorities, investigative organizations, our affiliates or in legal proceedings.</motion.p>
                <motion.p variants={termsParagraph}>We may share your personal information in the event of a merger, acquisition, or sale of all or a portion of our assets. Of course, we shall notify you via email and/or a prominent notice on our website and inform you of your rights.</motion.p>
                <motion.p variants={termsParagraph}>We share data in aggregate form and/or in a form which does not enable the recipient of such data to identify you, with third parties, for example, for industry analysis.</motion.p>
                <motion.p variants={termsParagraph}>Please note, our Site includes links to products or applications of third parties whose privacy practices may differ from Galantis. If you provide personal data to any of those third parties or their products, your data is governed by their privacy statements, and we encourage you to read these privacy statements carefully.</motion.p>
                <motion.p variants={termsParagraph}><strong>International Transfers.</strong><br />Our sharing your personal data in accordance with this Policy may involve transferring your data outside the European Economic Area (EEA).  Where necessary, we will transfer your personal information in compliance with applicable data protection laws and will implement suitable safeguards to ensure that your personal information is adequately secured by any third party that will access your information (for instance, by using the Standard Contractual Clauses as approved by the European Commission).</motion.p>
                <motion.p variants={termsParagraph}>If you have questions or wish to obtain more information about the international transfer of your personal information or the implemented safeguards, please send us an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}><strong>DATA SECURITY AND STORAGE</strong><br />We may use your personal information for our legitimate interests. For example, we rely on our legitimate interest to analyze and improve our Galantis Products and Services and the content on our websites, to send you notifications about Galantis Products and Services or to use your personal information for administrative, fraud detection or legal purposes. Where we process your personal information based on our legitimate interest and no opt-out mechanism is available to you (e.g. in your Galantis Product settings), you may exercise your right to object by sending an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}>Galantis is committed to protecting the security of your personal information. However, while we take reasonable precautions to guard the personal information we collect, no security system is impenetrable.</motion.p>
                <motion.p variants={termsParagraph}>We use a variety of appropriate technical and organizational measures and industry standards to protect your personal information and help prevent information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. For example, we store the personal data you provide on computer systems that have limited access and are in controlled facilities. Additionally, we ensure that our third-party data center vendors provide adequate security measures. Additionally, your data is protected with encryption. Moreover, your password is stored using one-way hash, which means that it cannot be recovered (or disclosed) by anyone, including Galantis (it can only be reset).</motion.p>
                <motion.p variants={termsParagraph}>You can only access your Galantis account information and our service through the use of an individual user login and password. To protect the confidentiality of your personal information, you must keep your password confidential and not disclose it to any other person. Please alert us immediately if you believe your password has been misused. Additionally, always log out and close your browser when you finish your session. <strong>Please note, we will never ask you to disclose your password.</strong></motion.p>
                <motion.p variants={termsParagraph}>If you have any questions about the security of your personal information, you can contact us at <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}><strong>Storage.</strong><br />Personal data collected by Galantis may be stored and processed in your region, in the United States (for instance in our major data centers), or in any other country where Galantis or its affiliates, subsidiaries or service providers are located or maintain facilities. Galantis has put in place adequate mechanisms to protect personal information when it is transferred internationally, for example by using the Standard Contractual Clauses as approved by the European Commission.
Galantis will retain your personal information for as long as we deem it necessary to enable you to use the website and your Galantis Product, to provide services to you, to comply with applicable laws (including those regarding document retention), resolve disputes with any parties and otherwise as necessary to allow us to conduct our business. All personal information we retain will be subject to this Policy and our internal retention guidelines. If you have a question about a specific retention period for certain types of personal information we process about you, please send an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}><strong>YOUR RIGHTS AND CHOICES</strong><br />We want you to be in control of how your personal information is used by us. Subject to local law, you can do this in the following ways:
                <ul>
                    <li>you can ask us for a copy of the personal information we hold about you;</li>
                    <li>you can inform us of any changes to your personal information, or if you want us to correct any of the personal information we hold about you;</li>
                    <li>in certain situations, you can ask us to erase, block or restrict the personal information we hold about you, or object to particular ways in which we are using your personal information; and</li>
                    <li>in certain situations, you can also ask us to send the personal information you have given us to a third party.</li>
                    <li>You can limit your browser or mobile device from providing certain information by adjusting the settings in the browser, operating system or device. Please consult the documentation for the applicable browser, operating system or device for the controls available to you.</li>
                    <li>You can stop receiving promotional emails from us by following the unsubscribe instructions in those emails. Note that unsubscribe is not available for certain emails concerning your relationship or dealings with us.</li>
                </ul></motion.p>
                <motion.p variants={termsParagraph}>Where we are using your personal information on the basis of your consent, you are entitled to withdraw that consent at any time. Moreover, where we process your personal information based on legitimate interest or the public interest, you have the right to object at any time to that use of your personal information.</motion.p>
                <motion.p variants={termsParagraph}>We rely on you to ensure that your personal information is complete, accurate and current. Please inform us promptly of any changes to, or inaccuracies of, your personal information by contacting <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}>We will respond to your request as soon as possible but certainly within 30 days. For more information about how you can control the collection and use of additional usage data and your communication, marketing and advertising preferences, please continue reading below.</motion.p>
                <motion.p variants={termsParagraph}>We are committed to working with you to obtain a fair resolution of any complaint or concern you may have about our use of your personal information. If, however, you believe that we have not been able to assist with your complaint or concern, you may have the right to lodge a complaint with the data protection authority in your country (if one exists in your country) or supervisory authority.</motion.p>
                <motion.p variants={termsParagraph}><strong>Galantis Account.</strong><br />If you wish to access, edit or remove profile information, change your password, close your account or request deletion of your personal data, you can do it by logging in to your Galantis account or Galantis app or sending an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>. If you cannot access certain personal information collected by Galantis via the Galantis account, you can always contact us by sending an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>. We will respond to any request to access or delete your personal data as soon as possible but certainly within 30 days.</motion.p>
                <motion.p variants={termsParagraph}><strong>Your Communication, Marketing and Advertising Preferences.</strong><br />You can opt out of receiving direct marketing communications from Galantis by following the instructions included in every email sent to you via the “Unsubscribe” tab. We respect your choice, and we will stop sending you promotional emails once you unsubscribe or change your communication settings.</motion.p>
                <motion.p variants={termsParagraph}>Please note, regardless of your communication settings, we will continue to communicate with you regarding changes to terms and conditions, policy updates, routine customer service messages such as information about current or past purchase, delivery of products, service interruptions, or data breaches.</motion.p>
                <motion.p variants={termsParagraph}>If you have opted out of receiving emails from us, we will not use your email for interest-based advertising activities.</motion.p>
                <motion.p variants={termsParagraph}><strong>Your California Privacy Rights.</strong><br />California residents may opt out of having their personally identifiable information shared with third parties for those third parties' direct marketing purposes by contacting us as described in the “Contact” section. Some web browsers may transmit “do-not-track” signals to the websites and other online services with which a user communicates. There is no industry standard that governs what, if anything, websites should do when they receive these signals. Galantis currently does not take action in response to these signals.</motion.p>
                <motion.p variants={termsParagraph}>Under California Civil Code Section 1798.83, if you are a California resident and your business relationship with us is primarily for personal, family or household purposes, you may request certain data regarding our disclosure if any, of information to third parties for the third parties' direct marketing purposes. To make such a request, please send an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a> with "Request for California Privacy information" in the subject line. You may make such a request up to once per calendar year. If applicable, we will provide to you via email a list of the categories of information disclosed to third parties for their direct marketing purposes during the immediately-preceding calendar year, along with the third parties' names and addresses. Please note that not all personal information sharing is covered by Section 1798.83's requirements.</motion.p>
                <motion.p variants={termsParagraph}><strong>How to Control Identifier Settings.</strong><br />You can control how these identifiers are used, including the ability to reset them, through your device settings. </motion.p>
                <motion.p variants={termsParagraph}>Galantis may work with third-party advertisers that use cookies and similar technologies to provide more relevant advertising about Galantis Products and Services on our websites and across the internet. To provide this 'interest-based advertising', the parties combine non-personal data about your online activities acquired over time, which help cater advertising that we deliver to you. Examples of this may include a Galantis advertisement on a Facebook page, or a Google-owned page such as a search results page or YouTube, or on a site within Google’s advertising network. We do not share your personal information as part of this process. Where necessary we obtain your consent to this process. At all times, you can opt out of these personalized ads from third-party advertisers and ad networks who are members of the Network Advertising Initiative (NAI) or who follow the Digital Advertising Alliance's Self-Regulatory Principles for Online Behavioral Advertising by visiting the opt-out pages on the NAI websites and DAA websites. Opting out may make the ads you see across the internet less relevant to you.</motion.p>
                <motion.p variants={termsParagraph}>If you have opted out of receiving communications from us, we will not use your email for interest-based advertising.<br />
                If you have any questions about the use of your personal information, please send an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a>.</motion.p>
                <motion.p variants={termsParagraph}>Galantis may modify or update this Policy when necessary to reflect customer feedback and changes in our Galantis Products and Service. Please review it regularly. When we update this Policy, we will revise the 'Last Update' date at the top of the Policy. If there are material changes to the Policy or in how Galantis uses your personal data, we will notify you either by posting a notice of such changes before they take effect or by directly sending you a notification. We encourage you to regularly review this Policy to learn more how Galantis is using and protecting your information.</motion.p>
                <motion.p variants={termsParagraph}><strong>COOKIES AND TRACKING TECHNOLOGIES</strong><br />We use cookies and similar technologies like pixels, tags, web beacons, and other identifiers to help us personalize our websites for you, remember your preferences, understand how users are using our websites or app, and help customize our marketing offerings.</motion.p>
                <motion.p variants={termsParagraph}>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.  This helps us to provide you with a smooth browsing experience and allows us to improve our Site and provide you with tailored content and advertising.</motion.p>
                <motion.p variants={termsParagraph}>Most web browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may impact your overall user experience. Some browsers offer a “Do Not Track” (“DNT”) signal whereby you may indicate your preference regarding tracking and cross-site tracking. Although we do not currently employ technology that recognizes DNT signals, we will only process your personal data in accordance with this Policy. A persistent cookie remains on your hard drive after you close your browser. Persistent cookies may be used by your browser on subsequent use of the Services. Persistent cookies can be removed by following your web browser's directions for removal of cookies. A session cookie is temporary and disappears after you close your browser. You can reset your web browser to refuse all cookies or to notify you when a cookie is being sent. However, some features of the Service may not function properly if cookies are disabled.  For more information on managing cookies, please go to www.allaboutcookies.org, or visit www.youronlinechoices.eu which has further information about behavioral advertising and online privacy.</motion.p><br />
                <div style={{ display: "grid", gridTemplateColumns: "33% 67%" }}>
                    <div><strong>Type Of Cookie<br /><br /></strong></div>
                    <div><strong>Purpose of Cookie</strong></div>
                    <div>Strictly Necessary</div>
                    <div>These cookies are essential in order to enable you to browse our Site and use its features. The information collected by these cookies relate to the operation of our Site, for example website scripting language and security tokens to maintain secure areas of our Site, including shopping baskets.<br /><br /></div>
                    <div>Functional</div>
                    <div>These cookies remember choices you make, for example the country you visit our Site from and your language to improve your experience of our Site. The information these cookies collect may be anonymized and cannot be used to track your browsing activity on other websites.<br /><br /></div>
                    <div>Targeting or Advertising</div>
                    <div>These cookies collect information about your browsing habits and inferred interests in order to make advertising more relevant to you. They are also used to limit the number of times you see an advert, as well as to measure the effectiveness of an advertising campaign. These cookies are usually placed by third-party advertising networks. They remember the other websites that you visit and this information is shared with third-party organizations, for example advertisers.<br /><br /></div>
                    <div>Social Media</div>
                    <div>These cookies allow you to share what you’ve been doing on our Site with social media organizations such as Facebook and Twitter. These cookies are not within our control. Please refer to the privacy policies of these organizations for how their cookies work.<br /><br /></div>
                </div>
                <motion.p variants={termsParagraph}>Please note that third parties may use cookies over which we have no control.</motion.p>
                <motion.p variants={termsParagraph}><strong>We may use cookies:</strong>
                <ul>
                    <li>to collect anonymized data on users behavior on the site. We may also capture what site a user has come from and what site they go to and also some demographic data (e.g. the user’s location) and we may use that data to analyze user behavior across multiple sites;</li>
                    <li>to categorize pools of users (on an anonymized basis) based on demographic and/or behavioral data and use this to target our advertising.</li>
                </ul></motion.p>
                <motion.p variants={termsParagraph}><strong>Web Beacons.</strong><br />We, or our third-party partners, may employ a software technology called web beacons (also known as web bugs, clear gifs or pixels) which helps us understand what content is effective, for example by counting the number of users who have visited these pages, and to understand usage patterns. Web beacons are tiny graphics with a unique identifier, similar in function to cookies, and are used to let us know when content is viewed. In contrast to cookies, which are stored on a user’s computer hard drive, web beacons are embedded on web pages, ads, and e-mail. We, or our third-party partners, may tie the information gathered by web beacons to the other information we collect about you.  </motion.p>
                <motion.p variants={termsParagraph}><strong>Log Files.</strong><br />In addition to disabling cookies and other tracking technologies as described above, you may opt-out of receiving targeted advertising from participating ad networks, audience segment providers, ad serving vendors, and other service providers by visiting websites operated by the Network Advertising Initiative, Digital Advertising Alliance, or European Interactive Digital Advertising Alliance.</motion.p>
                <motion.p variants={termsParagraph}>Log file information is automatically reported by your browser each time you access a web page. When you access or use the Services, our servers may automatically record certain log file information, including but not limited to your web request, Internet Protocol address, browser type, referring/exit pages and URLs, number of clicks and how you interact with links on the Services, domain names, landing pages, and pages viewed.</motion.p>
                <motion.p variants={termsParagraph}><strong>Third-Party Sites and Services.</strong><br />The Services may reference or provide links to other websites, applications, or resources. If you access any website, application, or resources provided by a third party, our Privacy Policy will not apply. Your interactions with such websites, applications, and resources are subject to the privacy policies of the third parties that operate them. Please review those policies carefully to understand how those parties will treat your information.</motion.p>
                <motion.p variants={termsParagraph}><strong>How We Protect Your Information.</strong><br />We take certain physical, technological and administrative measures to protect the information you provide through the Services against loss, theft, and unauthorized access, use, disclosure or modification. However, we cannot ensure or warrant the security of any information you transmit to us or guarantee that information on the Services may not be accessed, disclosed, altered or destroyed. Email sent to or from the Services may not be secure. You should use caution whenever submitting information online and take special care in deciding what information you send to us via email. We cannot guarantee that transmissions of your personal information will be fully secure and that third parties will never be able to defeat our security measures or the security measures of our partners. WE ASSUME NO LIABILITY FOR DISCLOSURE OF YOUR INFORMATION DUE TO TRANSMISSION ERRORS, THIRD-PARTY ACCESS OR CAUSES BEYOND OUR CONTROL.</motion.p>
                <motion.p variants={termsParagraph}><strong>USERS OF SITES IN THE EUROPEAN ECONOMIC AREA</strong><br />This section of this Policy applies only if you use the Site from a country that is a Member State of the European Economic Area, and supplements the information in this Policy.</motion.p>
                <motion.p variants={termsParagraph}>Users based in the European Union have the following legal rights in respect of their information:</motion.p>
                <motion.p variants={termsParagraph}>a. The right to require Galantis to confirm whether or not their information is being processed, the purpose of any such processing, the recipients of any information that has been disclosed, the period for which their information is to be stored and whether any automated decision-making processes are used in relation to their information;</motion.p>
                <motion.p variants={termsParagraph}>b. The right to require Galantis to rectify inaccurate information without undue delay;</motion.p>
                <motion.p variants={termsParagraph}>c. The right to request the erasure of their information. Subject to certain conditions, Galantis may be required to erase the information in response to such request where: (i) the information is no longer necessary in relation to the purpose for which it was collected, such as where a user chooses to terminate his or her use of the Service; (ii) where the processing of the information is based on the user’s consent, if the user  withdraws his or her consent; or (iii) the user objects to the processing of his or her information and there are no overriding legitimate grounds for the processing.</motion.p>
                <motion.p variants={termsParagraph}>d. Where Galantis has disclosed the information of a European Union user to a third party and the user requests the erasure or rectification of the data, Galantis will take all reasonable steps to inform the third party of such request.</motion.p>
                <motion.p variants={termsParagraph}>e. The right to restrict Galantis from processing their information in certain circumstances, such as where the accuracy of that information is disputed or an objection has been raised (see below). In such circumstances, Galantis will only process that information with the express consent of the user, or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest;</motion.p>
                <motion.p variants={termsParagraph}>f. The right to receive their information from Galantis in a structured, commonly used and machine-readable format;</motion.p>
                <motion.p variants={termsParagraph}>g. The right to object to the processing of their information by Galantis where Galantis has relied on the ‘legitimate interest’ basis for processing that information (see ‘Legal Basis for Data Processing’ below), in which case we will immediately stop processing the user’s information unless we have compelling legitimate grounds which override the interests, rights, and freedoms of the user; or the information is used for direct marketing purposes, in which case we will immediately stop processing the user’s information for such purposes.</motion.p>
                <motion.p variants={termsParagraph}>h. The right to lodge a complaint with the data protection supervisory authority of the EU member state where the user resides. The above legal rights are subject to various conditions and exceptions including where the data is used for statistical or scientific research purposes and the exercise of the right would prevent such purposes from being attained or would seriously impair their attainment.</motion.p>
                <motion.p variants={termsParagraph}>i. The above legal rights are subject to various conditions and exceptions including where the data is used for statistical or scientific research purposes and the exercise of the right would prevent such purposes from being attained or would seriously impair their attainment.</motion.p>
                <motion.p variants={termsParagraph}><strong>Legal Basis for Data Processing.</strong><br />We process personal data for the purposes set out in this Policy, as described above. Our legal basis to process personal data includes processing that is: necessary for the performance of the contract between you and us (for example, to provide you with the services you request and to identify and authenticate you so you may use the Site); necessary to comply with legal requirements (for example, to comply with applicable accounting rules and to make mandatory disclosures to law enforcement); necessary for our legitimate interests (for example, to manage our relationship with you and to improve the Site); and based on consent by our customers (for example, to communicate with you about our products and services and provide you with marketing information), which may subsequently be withdrawn at any time (by using preference settings in emails or by sending an email to <a href="mailto:contact@littleempiremusic.com" style={{ fontWeight: 700 }} target="_blank">contact@littleempiremusic.com</a> without affecting the lawfulness of processing based on consent before its withdrawal.</motion.p>
                <motion.p variants={termsParagraph}>We may use automated decision-making technologies, including profiling, to support our data processing activities. Our automated decision-making capabilities include logic that attempts to identify artists, music, communications, products, or offers that we believe may interest you. By using this logic, it helps us personalize your interactions with Galantis. For you, this means that you may see online advertisements, direct marketing communications (if you have subscribed), or other advertising or marketing messages or special offers based on your activity on our Sites or interactions with Galantis or our third-party partners.</motion.p>
                <motion.p variants={termsParagraph}>In some instances, you may be required to provide us with personal data for processing as described above, in order for us to be able to provide you to use all the features of the Site.</motion.p>
            </motion.div>
        </div>
    </motion.div>
</motion.div>

)
}

export default Privacy