import React, { useEffect, useState, useRef } from 'react'
import "../css/nav.css"
import { motion, AnimatePresence, easeIn } from "framer-motion"
import { Link } from 'react-router-dom'

const Nav = () => {

const [ showNav, setShowNav ] = useState(false);
const [ menu, setMenu ] = useState(false);
const [ nav1, setNav1 ] = useState(false);
const [ nav2, setNav2 ] = useState(false);
const [ nav3, setNav3 ] = useState(false);
const [ nav4, setNav4 ] = useState(false);
const [ nav5, setNav5 ] = useState(false);
const [ nav6, setNav6 ] = useState(false);
const [ nav7, setNav7 ] = useState(false);
const [ merch, setMerch ] = useState(false);
const [ swap, setSwap ] = useState();
const [ cross, setCross ] = useState(false)

const merchRef = useRef()

// const closeNav = async () => {
//     window.scrollTo({
//         top: 0,
//         behavior: "smooth"
//     })
//     setCross(false)
//     await new Promise((resolve) => setTimeout(resolve, 500))
//     setShowNav(false)
//  } 

const closeNav = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
    setCross(false)
    setShowNav(false)
 } 

 const closeNav2 = () => {
    setCross(false)
    setShowNav(false)
 } 



const textSwap = { 
    open: { y: -20, transition: { duration: 0.3 }},
    closed: { y: 0, transition: { duration: 0.3 }}
}

const noTextSwap = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: -56, transition: { duration: 1 }}}


const circleExpand = { 
    open: { scale: 1.15, transition: { duration: 0.6 }},
    closed: { scale: 0, transition: { duration: 0.6, delay: 0.3 }}
}

const circleShrink = { 
    open: { scale: 0, opacity: 0, transition: { duration: 1.2 }},
    closed: { scale: 1, opacity: 1, transition: { duration: 1 }}
}

const navBackgroundVariants = {
    open: { minHeight: "100vh", height: "100vh", transition: { delay: 0.2, ease: [0.9, 0.5, 0, 0.9], duration: 1 } },
    closed: { height: "0vh",  minHeight: "0vh", transition: { delay: 0.9, ease: [0.5, 0.1, 0, 0.9],  duration: 0.8 } },
}

const navBackgroundVariants2 = {
    open: { minHeight: "100vh", height: "100vh", transition: { delay: 0.6, ease: [0.5, 0.1, 0, 0.9], duration: 1.2 } },
    closed: { height: "0vh", minHeight: "0vh", transition: { delay: 0.5, ease: [0.9, 0.5, 0, 0.9], duration: 0.8 } },
}

const navNav = {
    open: { opacity: 1 },
    closed: { opacity: 1, transition: { duration: 0.5, delay: 0.5 }},
    exit: { opacity: 1, transition: { duration: 0.5, delay: 0.5 }}
}

const merchHeight = { open: { height: 48, transition: { duration: 0.4 } }, closed: { height: 0, transition: { duration: 0.4 } } }
const merchLocations1 = { open: { y: 0, transition: { delay: 0.1, duration: 0.5 } }, closed: { y: 30 } }
const merchLocations2 = { open: { y: 0, transition: { delay: 0.2, duration: 0.5 } }, closed: { y: 30 } }
const merchLocations3 = { open: { y: 0, transition: { delay: 0.3, duration: 0.5 } }, closed: { y: 30 } }

const staggerUnder = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delayChildren: 0.8, staggerChildren: 0.15, ease: "easeIn", delay: 5 }}
  }

const merchFuncs = () => {
    setMerch(true)
    setSwap(noTextSwap)
}  

const navButton = () => {
    setCross(true)
    setShowNav(true)
}

useEffect(() => {
let handler = (e) => { 
    if(!merchRef.current.contains(e.target)){
        setMerch(false)
        setSwap(nave)
    }
}

document.addEventListener("mousedown", handler)
}, [setMerch])


const navhom = { open: { width: "180px", x:0, opacity: 1, transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 0.8, delay: 1.5 }}, closed: { opacity: 1, width: "0%", x: -500, transition: { duration: 1, ease: [0.5, 0.1, 0, 0.9], delay: 0.00 }}}
const navtou = { open: { width: "170px", x:0, opacity: 1, transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 0.8, delay: 1.6 }}, closed: { opacity: 1, width: "0%", x: -500, transition: { duration: 1, ease: [0.5, 0.1, 0, 0.9], delay: 0.05 }}}
const navabo = { open: { width: "205px", x:0, opacity: 1, transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 0.8, delay: 1.7 }}, closed: { opacity: 1, width: "0%", x: -500, transition: { duration: 1, ease: [0.5, 0.1, 0, 0.9], delay: 0.10 }}}
const navmus = { open: { width: "225px", x:0, opacity: 1, transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 0.8, delay: 1.8 }}, closed: { opacity: 1, width: "0%", x: -500, transition: { duration: 1, ease: [0.5, 0.1, 0, 0.9], delay: 0.15 }}}
const navmer = { open: { width: "215px", x:0, opacity: 1, transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 0.8, delay: 1.9 }}, closed: { opacity: 1, width: "0%", x: -500, transition: { duration: 1, ease: [0.5, 0.1, 0, 0.9], delay: 0.20 }}}
const navcon = { open: { width: "270px", x:0, opacity: 1, transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 0.8, delay: 2.0 }}, closed: { opacity: 1, width: "0%", x: -500, transition: { duration: 1, ease: [0.5, 0.1, 0, 0.9], delay: 0.25 }}}
const navsub = { open: { width: "330px", x:0, opacity: 1, transition: { ease: [0.5, 0.1, -0.05, 0.9], duration: 0.8, delay: 2.1 }}, closed: { opacity: 1, width: "0%", x: -500, transition: { duration: 1, ease: [0.5, 0.1, 0, 0.9], delay: 0.30 }}}

const block1 = { open: { width: "100%", transition: { delay: 1 } }, closed: { width: 0, transition: { duration: 0.8, ease: [0.5, 0.1, 0, 0.9], delay: 2.6 }}}
const block2 = { open: { width: "100%", transition: { delay: 1 } }, closed: { width: 0, transition: { duration: 0.8, ease: [0.5, 0.1, 0, 0.9], delay: 2.7 }}}
const block3 = { open: { width: "100%", transition: { delay: 1 } }, closed: { width: 0, transition: { duration: 0.8, ease: [0.5, 0.1, 0, 0.9], delay: 2.8 }}}
const block4 = { open: { width: "100%", transition: { delay: 1 } }, closed: { width: 0, transition: { duration: 0.8, ease: [0.5, 0.1, 0, 0.9], delay: 2.9 }}}
const block5 = { open: { width: "100%", transition: { delay: 1 } }, closed: { width: 0, transition: { duration: 0.8, ease: [0.5, 0.1, 0, 0.9], delay: 3.0 }}}
const block6 = { open: { width: "100%", transition: { delay: 1 } }, closed: { width: 0, transition: { duration: 0.8, ease: [0.5, 0.1, 0, 0.9], delay: 3.1 }}}
const block7 = { open: { width: "100%", transition: { delay: 1 } }, closed: { width: 0, transition: { duration: 0.8, ease: [0.5, 0.1, 0, 0.9], delay: 3.2 }}}

const cross1 = { open: { width: "35px", transition: { duration: 0.8, ease: [0.5, 0.1, -0.05, 0.9], delay: 3.2 }}, closed: { width: "0px" }}
const cross2 = { open: { width: "35px", transition: { duration: 0.8, ease: [0.5, 0.1, -0.05, 0.9], delay: 3.5 }}, closed: { width: "0px" }}

const nava = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: 0, transition: { duration: 1 }}}
const navb = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: 0, transition: { duration: 1 }}}
const navc = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: 0, transition: { duration: 1 }}}
const navd = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: 0, transition: { duration: 1 }}}
const nave = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: 0, transition: { duration: 1 }}}
const navf = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: 0, transition: { duration: 1 }}}
const navg = { open: { y: -56, transition: { ease: "easeInOut", duration: 0.4 }}, closed: { y: 0, transition: { duration: 1 }}}

useEffect (() => {
    setSwap(nave)
}, [])

return (
<div>
    <div onMouseEnter={() => setMenu(true)} onMouseLeave={() => setMenu(false)} onClick={navButton} className="mobile-burger-container">
        <div className="mobile-burger-top"></div>
        <div className="mobile-burger-bottom"></div>
    </div>
    <motion.div onMouseEnter={() => setMenu(true)} onMouseLeave={() => setMenu(false)} onClick={navButton} className="menu-container">
        <motion.div variants={circleExpand} initial="closed" animate={ menu ? "open" : "closed" } className='menu-button'>
        </motion.div>
        <motion.div className='menu-button' variants={circleShrink} initial="closed" animate={ menu ? "open" : "closed" }>
        </motion.div>
        <div className='menu-text-container'>
            <div className='menu-text-mask'>
                <motion.div variants={{ textSwap }} initial="closed" animate={ menu ? "open" : "closed" } className='menu-text'>MENU<br />OPEN</motion.div>
            </div> 
        </div> 
    </motion.div> 
    <motion.div style={{ pointerEvents: showNav ? "auto" : "none" }} className='navnav' variants={navNav} initial="closed" animate={ showNav ? "open" : "exit"}> 

        <motion.div variants={navBackgroundVariants} initial="closed" animate={ showNav ? "open" : "closed"} className="nav_bg1" style={ showNav ? { bottom: 0 } : { top: 0 } }>
            <motion.div variants={navBackgroundVariants2} initial="closed" animate={ showNav ? "open" : "closed"} className="nav_bg2" style={ showNav ? { bottom: 0 } : { top: 0 } }>
                <motion.button className='nav-cross' whileHover={{ rotate: 180 }} transition={{ duration: 0.3 }} onClick={closeNav2}>
                    <motion.div variants={cross1} initial="closed" animate={ cross ? "open" : "closed" } className='nav-cross-1'></motion.div>
                    <motion.div variants={cross2} initial="closed" animate={ cross ? "open" : "closed" } className='nav-cross-2'></motion.div>
                </motion.button>
            </motion.div>
        </motion.div>
        <motion.div className="navli" variants={staggerUnder} initial="closed" animate="open">
                    <motion.ul>
                        <motion.li><motion.div id='navli1' variants={navhom} initial="closed" animate={ showNav ? "open" : "closed"} ><motion.div className="nav-text" variants={nava} onMouseEnter={() => setNav1(true)} onMouseLeave={() => setNav1(false)} animate={ nav1 ? "open" : "closed" }><div className="top-number">01<br /><span style={{ color: "#ddd" }}>01</span></div><div onClick={closeNav} className="nav-text2"><Link style={{ textDecoration: "none" }} to="/">HOME<br /><span>HOME</span></Link></div></motion.div><motion.div className="nav-mask" variants={block1} initial="open" animate={ showNav ? "closed" : "open"}></motion.div></motion.div></motion.li><br />
                        <motion.li><motion.div id='navli2' variants={navtou} initial="closed" animate={ showNav ? "open" : "closed"} ><motion.div className="nav-text" variants={navb} onMouseEnter={() => setNav2(true)} onMouseLeave={() => setNav2(false)} animate={ nav2 ? "open" : "closed" }><div className="top-number">02<br /><span style={{ color: "#ddd" }}>02</span></div><div onClick={closeNav} className="nav-text2"><Link style={{ textDecoration: "none" }} to="/tour">TOUR<br /><span>TOUR</span></Link></div></motion.div><motion.div className="nav-mask" variants={block2} initial="open" animate={ showNav ? "closed" : "open"}></motion.div></motion.div></motion.li><br />
                        <motion.li><motion.div id='navli3' variants={navabo} initial="closed" animate={ showNav ? "open" : "closed"} ><motion.div className="nav-text" variants={navc} onMouseEnter={() => setNav3(true)} onMouseLeave={() => setNav3(false)} animate={ nav3 ? "open" : "closed" }><div className="top-number">03<br /><span style={{ color: "#ddd" }}>03</span></div><div onClick={closeNav} className="nav-text2"><Link style={{ textDecoration: "none" }} to="/about">ABOUT<br /><span style={{ color: "#ddd" }}>ABOUT</span></Link></div></motion.div><motion.div className="nav-mask" variants={block3} initial="open" animate={ showNav ? "closed" : "open"}></motion.div></motion.div></motion.li><br />
                        <motion.li><motion.div id='navli4' variants={navmus} initial="closed" animate={ showNav ? "open" : "closed"} ><motion.div className="nav-text" variants={navd} onMouseEnter={() => setNav4(true)} onMouseLeave={() => setNav4(false)} animate={ nav4 ? "open" : "closed" }><div className="top-number">04<br /><span style={{ color: "#ddd" }}>04</span></div><div onClick={closeNav} className="nav-text2"><Link style={{ textDecoration: "none" }} to="/music">MUSIC<br /><span style={{ color: "#ddd" }}>MUSIC</span></Link></div></motion.div><motion.div className="nav-mask" variants={block4} initial="open" animate={ showNav ? "closed" : "open"}></motion.div></motion.div></motion.li><br />
                        <motion.li><motion.div id='navli5' variants={navmer} initial="closed" animate={ showNav ? "open" : "closed"} ><motion.div className="nav-text" variants={swap} onMouseEnter={() => setNav5(true)} onMouseLeave={() => setNav5(false)} animate={ nav5 ? "open" : "closed" }><div className="top-number">05<br /><span style={{ color: "#ddd" }}>05</span></div><div ref={merchRef} onClick={merchFuncs} className="nav-text2">MERCH<br /><span>MERCH</span></div></motion.div><motion.div className="nav-mask" variants={block5} initial="open" animate={ showNav ? "closed" : "open"}></motion.div></motion.div></motion.li><br />
                        <motion.div className="nav-merch-options" variants={merchHeight} initial="closed" animate={merch ? "open" : "closed"}>
                            <div ref={merchRef} className='merch-locations-wrapper'> 
                                <motion.div variants={merchLocations1} initial="closed" animate={merch ? "open" : "closed"} className="merch3"><motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#6b21b4" }} href='https://galantis.merchtable.com/' target="_blank">US</motion.a></motion.div>
                                <motion.div variants={merchLocations2} initial="closed" animate={merch ? "open" : "closed"} className="merch4">/ <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#6b21b4" }} href='https://shop.mu-mo.net/gjos/' target="_blank">JAPAN</motion.a></motion.div>
                                <motion.div variants={merchLocations3} initial="closed" animate={merch ? "open" : "closed"} className="merch5">/ <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#6b21b4" }} href='https://store.100-percent.co.uk/collections/galantis' target="_blank">UK & EUROPE</motion.a></motion.div></div>
                            </motion.div>
                        <motion.li><motion.div id='navli6' variants={navcon} initial="closed" animate={ showNav ? "open" : "closed"} ><motion.div className="nav-text" variants={navf} onMouseEnter={() => setNav6(true)} onMouseLeave={() => setNav6(false)} animate={ nav6 ? "open" : "closed" }><div className="top-number">06<br /><span style={{ color: "#ddd" }}>06</span></div><div onClick={closeNav} className="nav-text2"><Link style={{ textDecoration: "none" }} to="/contact">CONTACT<br /><span>CONTACT</span></Link></div></motion.div><motion.div className="nav-mask" variants={block6} initial="open" animate={ showNav ? "closed" : "open"}></motion.div></motion.div></motion.li><br />
                        <motion.li><motion.div id='navli7' variants={navsub} initial="closed" animate={ showNav ? "open" : "closed"} ><motion.div className="nav-text" variants={navg} onMouseEnter={() => setNav7(true)} onMouseLeave={() => setNav7(false)} animate={ nav7 ? "open" : "closed" }><div className="top-number">07<br /><span style={{ color: "#ddd" }}>07</span></div><div onClick={closeNav} className="nav-text2"><Link style={{ textDecoration: "none" }} to="/subscribe">SUBSCRIBE<br /><span>SUBSCRIBE</span></Link></div></motion.div><motion.div className="nav-mask" variants={block7} initial="open" animate={ showNav ? "closed" : "open"}></motion.div></motion.div></motion.li>
                    </motion.ul>
                </motion.div>
    </motion.div>
</div>
)
}

export default Nav