import React, { useRef } from 'react'
import "../css/footer.css"
import Copyright from './Copyright'
import { motion, useTransform, useScroll } from "framer-motion"

const Footer = ({ windowWidth }) => {

  const targetRef = useRef()

  const { scrollYProgress } = useScroll({
      target: targetRef,
      offset: ["start end", "end end"]
  })

  const pathLength = useTransform(scrollYProgress, [0.3, 0.6, 1], [0, 1, 1])
  const y = useTransform(scrollYProgress, [0.1, 1], [150, 0])


  return (
    <div ref={targetRef} className='footer-container'>
        <div className='heart-container'>
          <motion.svg style={{ y }} fill='none' width="100" height="100">
              <motion.polygon style={{ pathLength }} points='2, 26 26, 2 50, 26 74, 2 98,26 50,74 2,26' x1="58" y1="2" x2="2" y2="58" x3="5" y3="5" stroke="black" />
          </motion.svg>
        </div>
        <div className="marquees">         
        <motion.div animate={{ x: -1312 }} transition={{ duration: 20, repeat: Infinity, ease: "linear" }} className="socials-marquee">
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.instagram.com/wearegalantis/' target="_blank">INSTAGRAM</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://twitter.com/wearegalantis' target="_blank">TWITTER</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.youtube.com/channel/UC0YlhwQabxkHb2nfRTzsTTA' target="_blank">YOUTUBE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.tiktok.com/@wearegalantis' target="_blank">TIKTOK</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.instagram.com/wearegalantis/' target="_blank">INSTAGRAM</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://twitter.com/wearegalantis' target="_blank">TWITTER</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.youtube.com/channel/UC0YlhwQabxkHb2nfRTzsTTA' target="_blank">YOUTUBE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.tiktok.com/@wearegalantis' target="_blank">TIKTOK</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.instagram.com/wearegalantis/' target="_blank">INSTAGRAM</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://twitter.com/wearegalantis' target="_blank">TWITTER</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.youtube.com/channel/UC0YlhwQabxkHb2nfRTzsTTA' target="_blank">YOUTUBE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.tiktok.com/@wearegalantis' target="_blank">TIKTOK</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.instagram.com/wearegalantis/' target="_blank">INSTAGRAM</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://twitter.com/wearegalantis' target="_blank">TWITTER</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.youtube.com/channel/UC0YlhwQabxkHb2nfRTzsTTA' target="_blank">YOUTUBE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.tiktok.com/@wearegalantis' target="_blank">TIKTOK</motion.a>
        </motion.div>
        <motion.div animate={{ x: 0 }} initial={{ x:-1540 }} transition={{ duration: 18, repeat: Infinity, ease: "linear" }} className="socials-marquee2">
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.facebook.com/wearegalantis/' target="_blank">FACEBOOK</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://open.spotify.com/artist/4sTQVOfp9vEMCemLw50sbu' target="_blank">SPOTIFY</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://music.apple.com/us/artist/galantis/543322169' target="_blank">APPLE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.amazon.com/music/player/artists/B008LQNRR8/galantis' target="_blank">AMAZON</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://discord.com/invite/G3dZTTqBzf' target="_blank">DISCORD</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.facebook.com/wearegalantis/' target="_blank">FACEBOOK</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://open.spotify.com/artist/4sTQVOfp9vEMCemLw50sbu' target="_blank">SPOTIFY</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://music.apple.com/us/artist/galantis/543322169' target="_blank">APPLE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.amazon.com/music/player/artists/B008LQNRR8/galantis' target="_blank">AMAZON</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://discord.com/invite/G3dZTTqBzf' target="_blank">DISCORD</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.facebook.com/wearegalantis/' target="_blank">FACEBOOK</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://open.spotify.com/artist/4sTQVOfp9vEMCemLw50sbu' target="_blank">SPOTIFY</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://music.apple.com/us/artist/galantis/543322169' target="_blank">APPLE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.amazon.com/music/player/artists/B008LQNRR8/galantis' target="_blank">AMAZON</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://discord.com/invite/G3dZTTqBzf' target="_blank">DISCORD</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.facebook.com/wearegalantis/' target="_blank">FACEBOOK</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://open.spotify.com/artist/4sTQVOfp9vEMCemLw50sbu' target="_blank">SPOTIFY</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://music.apple.com/us/artist/galantis/543322169' target="_blank">APPLE</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://www.amazon.com/music/player/artists/B008LQNRR8/galantis' target="_blank">AMAZON</motion.a>
            <motion.a style={{ textDecoration: "none" }} whileHover={{ color: "#fff" }} href='https://discord.com/invite/G3dZTTqBzf' target="_blank">DISCORD</motion.a>
        </motion.div>
        </div>  
        <Copyright />
    </div>
  )
}

export default Footer