import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations, useEnvironment, Environment } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/piiperrx.gltf");
  const { actions } = useAnimations(animations, group);
  const ref = useRef()

  useEffect(() => {
    const headMove = actions.headmotion
    headMove.play()
  }, [])

  useFrame((_, delta) => {
    ref.current.material.map.offset.x += 0.05 * delta
  })

  materials['Material.001'].roughness = 0.09

  return (
    <>
    <Environment 
    files={[
      '/envmap/px.jpg',
      '/envmap/nx.jpg',
      '/envmap/py.jpg',
      '/envmap/ny.jpg',
      '/envmap/pz.jpg',
      '/envmap/nz.jpg',
    ]}
    />
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
        ref={ ref }
          name="Plane002"
          castShadow
          receiveShadow
          geometry={nodes.Plane002.geometry}
          material={materials["Material.001"]}
          rotation={[Math.PI / 3, 0, 0]}
        />
      </group>
    </group>
    </>
  );
}

useGLTF.preload("/piiperrx.gltf");