import { useFrame } from '@react-three/fiber'
import { Sparkles } from '@react-three/drei'
import { useState, useEffect } from 'react'
import Model from "./Model.jsx"

export default function Experience() {

function useWindowSize() {
    const [size, setSize] = useState(window.innerWidth)
        useEffect(() => {
            const handleResize = () => {
                setSize(window.innerWidth)
                }
            window.addEventListener("resize", handleResize)
            return () => {
                window.removeEventListener("resize", handleResize)
            }
        }, [])
    return size
}
    
const width = useWindowSize()

function clamping() {
    if (width > 1000){ 
        return 1000
    }
    else {
        return width }
}
    
const clampedAmount = clamping()
    
const shiftAmount = { 
    distance: (1100 - clampedAmount) * 0.014
}

const shift = shiftAmount.distance + 10

useFrame((state, delta) =>
{
    state.camera.position.z = shift
})

return <>


<Sparkles
    size={ 8 }
    scale={ [20, 16, 10] }
    count={ 80 }
    color={"white"}
/>

<Model scale={3} position={[0, 0.7, 0]} />

</>
}