import React, { useState, useEffect } from 'react'
import "../css/giglist.css"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import ticket from "../assets/ticket.svg"


const Giglist = (props) => {

const [ tickets, setTickets ] = useState(false)

const getTickets = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
}

const { ref, inView } = useInView()
const animation = useAnimation()

useEffect(() => {
    if(inView){
        animation.start({ y: 0, transition: { ease: "easeInOut", duration: 0.8} })
    }
    if(!inView){
        animation.start({ y: 50, transition: { ease: "easeInOut", duration: 0.3 } })
    }
}, [inView])

const months = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC" ]
const getMonth = parseInt(props.date2 - 1)
const abbMonth = months[getMonth]

console.log(getMonth)

  return (
        <motion.a style={{ textDecoration: "none" }} onMouseEnter={() => setTickets(true)} onMouseLeave={() => setTickets(false)} href={props.tickets} target="_blank">
            <motion.div className='gig-entry'>
                <div className="event-date">
                    <motion.div animate={ animation } className="date" style={{ minWidth: '250px' }}>
                    {props.date1 === props.date3 ? `${abbMonth} ${props.date1}` 
                    : props.date1 ? `${abbMonth} ${props.date1}-${props.date3}` 
                    : "TBA"}
                    </motion.div>
                </div>
                <div ref={ref} className="event">
                    <motion.div animate={ animation }  className="event-location">
                    {props.event}<br />
                    {props.city}, {props.country} 
                    </motion.div>
                </div>
                <div className="tickets"><motion.div animate={ animation } className="buy">TICKETS</motion.div></div>
            </motion.div>
            <motion.div variants={getTickets} animate={tickets ? "open" : "closed"} className="buy-overlay">
            <motion.div animate={{ x: -445 }} transition={{ duration: 5, repeat: Infinity, ease: "linear" }} className="buy-text">GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img className="ticket-image" src={ticket} width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />
            &nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;GET&nbsp;TICKETS&nbsp;&nbsp;&nbsp;<img src={ticket} className="ticket-image" width="45" height="45" alt="ticket" />&nbsp;&nbsp;&nbsp;</motion.div></motion.div>
        </motion.a>
  )
}

export default Giglist