import React from 'react'

const ScrollSnap = () => {
  return (
    <div style={{ scrollSnapType: "y mandatory", position: "relative", height: "400vh" }}>
        <div style={{ scrollSnapAlign: "start", backgroundColor: "red", height: "100vh", position: "relative" }}></div>
        <div style={{ scrollSnapAlign: "start", backgroundColor: "blue", height: "90vh", position: "relative" }}></div>
        <div style={{ scrollSnapAlign: "start", backgroundColor: "red", height: "100vh", position: "relative" }}></div>
        <div style={{ scrollSnapAlign: "start", backgroundColor: "blue", height: "90vh", position: "relative" }}></div>
    </div>
  )
}

export default ScrollSnap