import React, { Suspense, useEffect, useState } from 'react'
import "../css/loader.css"
import galantisg from "../../public/galantis-g.svg"
import galantisa from "../../public/galantis-a1.svg"
import galantisl from "../../public/galantis-l.svg"
import galantisa2 from "../../public/galantis-a2.svg"
import galantisn from "../../public/galantis-n.svg"
import galantist from "../../public/galantis-t.svg"
import galantisi from "../../public/galantis-i.svg"
import galantiss from "../../public/galantis-s.svg"
import { motion } from "framer-motion"

 
const overflowStagger = {
    closed: { opacity: 1 },
    open: { opacity: 0, transition: { delay: 5.5, duration: 2 } }
}


const galantisLogo = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1, ease: [0.5, 0.7, 0.05, 0.9] }}}
const galantisLogo2 = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1.1, ease: [0.5, 0.7, 0.05, 0.9] }}}
const galantisLogo3 = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1.2, ease: [0.5, 0.7, 0.05, 0.9] }}}
const galantisLogo4 = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1.3, ease: [0.5, 0.7, 0.05, 0.9] }}}
const galantisLogo5 = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1.4, ease: [0.5, 0.7, 0.05, 0.9] }}}
const galantisLogo6 = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1.5, ease: [0.5, 0.7, 0.05, 0.9] }}}
const galantisLogo7 = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1.6, ease: [0.5, 0.7, 0.05, 0.9] }}}
const galantisLogo8 = { closed: { y: 70 }, open: { y: [70, 0], transition: { times: [0, 0.2], duration: 4, delay: 1.7, ease: [0.5, 0.7, 0.05, 0.9] }}}
 

const Loader = () => {

  const [loader, setLoader] = useState(true)

  useEffect (() => {
    setTimeout(() => {
     setLoader(false)
    }, 10000)
  }, [])
  

  return (
    <>
    { loader &&
    <motion.div variants={overflowStagger} initial="closed" animate="open" className="black-screen">
        <motion.div className="galantis-container2">
            <Suspense>
              <motion.div className="overflow-cut">
                <motion.img variants={ galantisLogo } initial="closed" animate="open" className='galantis-load' src={ galantisg } />
                <motion.img variants={ galantisLogo2 } initial="closed" animate="open" className='galantis-load' src={ galantisa } />
                <motion.img variants={ galantisLogo3 } initial="closed" animate="open" className='galantis-load' src={ galantisl } />
                <motion.img variants={ galantisLogo4 } initial="closed" animate="open" className='galantis-load' src={ galantisa2 } />
                <motion.img variants={ galantisLogo5 } initial="closed" animate="open" className='galantis-load' src={ galantisn } />
                <motion.img variants={ galantisLogo6 } initial="closed" animate="open" className='galantis-load' src={ galantist } />
                <motion.img variants={ galantisLogo7 } initial="closed" animate="open" className='galantis-load' src={ galantisi } />
                <motion.img variants={ galantisLogo8 } initial="closed" animate="open" className='galantis-load' src={ galantiss } />
              </motion.div>
            </Suspense>
        </motion.div>
    </motion.div>
   }
   </>
  )
}

export default Loader