import { Plane, Float, useTexture } from '@react-three/drei'
import React, { Suspense, useEffect } from 'react'
import { motion } from "framer-motion-3d"

const Releases = ( {PharmacyMid, AviaryMid, ChurchMid, RunawayMid, FaithMid, NoMoneyMid, DamnMid, HeartbreakMid, YouMid, PBJMid, windowWidth, pharmacyt2, pharmacyt4, pharmacyt5, pharmacyt6, pharmacyt62, pharmacyt7} ) => {

const r3fPharmacy = useTexture(PharmacyMid)
const r3fAviary = useTexture(AviaryMid)
const r3fChurch = useTexture(ChurchMid)
const r3fRunaway = useTexture(RunawayMid)
const r3fFaith = useTexture(FaithMid)
const r3fNoMoney = useTexture(NoMoneyMid)
const r3fDamn = useTexture(DamnMid)
const r3fHeartbreak = useTexture(HeartbreakMid)
const r3fYou = useTexture(YouMid)
const r3fPBJ = useTexture(PBJMid)


const transient = { duration: 2, delay: 0.3, ease: [0.5, 0.1, -0.05, 0.95] }
const transient2 = { duration: 2, delay: 0.3, ease: [0.5, 0.1, -0.05, 0.95] }
const transient3 = { duration: 2, ease: [0.5, 0.1, -0.05, 0.95] }


  return (
    <>
        <group position={ windowWidth > 1000 ? [-1.5, 0, 0] : [-.58 , 0.3, 0]}>
            <motion.group transition={transient} animate={ pharmacyt7 ? { y: 8 } : { y: 0 } }>
                <motion.group initial={{ y: -8 }} animate={{ y: 0, transition: transient }} exit={{ y: -8, transition: transient }}>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.3]} rotationIntensity={0}>
                        <motion.mesh animate={ pharmacyt2 && !pharmacyt4 ? {x: 0, y: 0, z: 3} : {x: -2.2, y: -1.4, z: 1}} transition={{ duration: 1.4, ease: [0.5, 0.1, -0.05, 0.95] }} position={[0, 0, 3]}>
                            <Plane>
                            <meshBasicMaterial map={r3fPharmacy} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.4]} rotationIntensity={0}>
                        <motion.mesh animate={ pharmacyt4 && !pharmacyt5 ? {x: 0, y: 0, z: 3} : {x: -0.8, y: -0.6, z: 1}} transition={{ duration: 1.4, ease: [0.5, 0.1, -0.05, 0.95] }} position={[-1.8, -1, 1]}>
                            <Plane>
                                <meshBasicMaterial map={r3fAviary} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.2]} rotationIntensity={0}>
                        <motion.mesh animate={ pharmacyt5 && !pharmacyt6 ? {x: 0, y: 0, z: 3} : {x: 1, y: 2, z: 2}} transition={{ duration: 1.4, ease: [0.5, 0.1, -0.05, 0.95] }} position={[1, 2, 2]}>
                            <Plane>
                                <meshBasicMaterial map={r3fChurch} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                </motion.group>  
                <motion.group initial={{ y: -8 }} animate={{ y: 0, transition: transient2 }}>  
                    <Float floatIntensity={0.1} floatingRange={[0, 0.3]} rotationIntensity={0}>
                        <motion.mesh animate={ pharmacyt6 && !pharmacyt62 ? {x: 0, y: 0, z: 3} : {x: 0.2, y: 1.5, z: 0}} transition={{ duration: 1.4, ease: [0.5, 0.1, -0.05, 0.95] }} position={[0.2, 1.5, 0]}>
                            <Plane>
                                <meshBasicMaterial map={r3fHeartbreak} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.5]} rotationIntensity={0}>
                        <motion.mesh position={[1.7, -1.5, 1]}>
                            <Plane>
                                <meshBasicMaterial map={r3fRunaway} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.5]} rotationIntensity={0}>
                        <motion.mesh position={[-1.6, 1.8, 2]}>
                            <Plane>
                                <meshBasicMaterial map={r3fYou} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.5]} rotationIntensity={0}>
                        <motion.mesh position={[-1.7, 0.5, 2.5]}>
                            <Plane>
                                <meshBasicMaterial map={r3fPBJ} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                </motion.group>  
                <motion.group initial={{ y: -8 }} animate={{ y: 0, transition: transient3 }}> 
                    <Float floatIntensity={0.1} floatingRange={[0, 0.5]} rotationIntensity={0}>
                        <motion.mesh animate={pharmacyt62 ? {x: 0, y: 0, z: 3} : {x: -2.2, y: -2, z: 2}} transition={{ duration: 1.4, ease: [0.5, 0.1, -0.05, 0.95] }}>
                            <Plane>
                                <meshBasicMaterial map={r3fDamn} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.5]} rotationIntensity={0}>
                        <motion.mesh position={[-1.8, -3, 1]}>
                            <Plane>
                                <meshBasicMaterial map={r3fFaith} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                    <Float floatIntensity={0.1} floatingRange={[0, 0.5]} rotationIntensity={0}>
                        <motion.mesh position={[2.1, 1.7, 1]}>
                            <Plane>
                                <meshBasicMaterial map={r3fNoMoney} />
                            </Plane>
                        </motion.mesh>
                    </Float>
                </motion.group>    
            </motion.group>
        </group>
    </>
  )
}

export default Releases