import React, { useEffect, useState } from 'react'
import './style.css'
import Loader from './components/Loader'
import Nav from './components/Nav' 
import Logo from './components/Logo'
import Home from './components/Home'
import Tour from './components/Tour'
import Music from './components/Music'
import Contact from './components/Contact'
import Subscribe from './components/Subscribe'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import Terms from './components/Terms'
import { ScrollContext } from './helpers/ScrollContext'
import { AnimatePresence } from 'framer-motion'
import { TermsContext } from './helpers/TermsContext'
import { PrivacyContext } from './helpers/PrivacyContext'
import Privacy from './components/Privacy'
import About from './components/About'
import ScrollSnap from './components/ScrollSnap'
import { motion } from "framer-motion"

function App() {

  const [ scroller, setScroller ] = useState(false)
  const [ terms, showTerms ] = useState(false)
  const [ privacy, showPrivacy ] = useState(false)
  const [ overlay, setOverlay ] = useState(false)
  const [ windowWidth, setWindowWidth ] = useState()

  useEffect(() => {
    scroller ?  
    document.documentElement.style.setProperty('--freeze-screen', 'hidden') 
    : 
    document.documentElement.style.setProperty('--freeze-screen', 'auto') 
  }, [scroller]
  )

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [windowWidth])

  return ( 
    <ScrollContext.Provider value={{scroller, setScroller}}>
      <TermsContext.Provider value={{terms, showTerms}}>
        <PrivacyContext.Provider value={{privacy, showPrivacy}}>
          <Router>
            <ScrollToTop />
            <div className="app">
              <Loader />
              <Logo />
              <Nav />
              <motion.div initial={{ opacity: 0 }} animate={ overlay ? { opacity: 1 } : { overlay: 0 }} transition={{ duration: 0.5 }} className="overlay"></motion.div>
              { terms &&
              <AnimatePresence mode="wait">
              <Terms windowWidth={ windowWidth } />
              </AnimatePresence>
              }
              { privacy &&
              <AnimatePresence mode="wait">
              <Privacy windowWidth={ windowWidth } />
              </AnimatePresence>
              }
              <Routes>
                <Route path="/" element={ <Home setOverlay={ setOverlay } />} />
                <Route path="/tour" element={ <Tour />} />
                <Route path="/about" element={ <About windowWidth={ windowWidth } /> } />
                <Route path="/music" element={ <Music />} />
                <Route path="/contact" element={ <Contact />} />
                <Route path="/subscribe" element={ <Subscribe />} />
                <Route path="/scroll" element={ <ScrollSnap />} />
              </Routes>
            </div>
          </Router>
        </PrivacyContext.Provider>
      </TermsContext.Provider>
    </ScrollContext.Provider>
  );
}

export default App;