import React, { useState, useEffect, useRef } from 'react'
import MusicTrack from './MusicTrack'
import "../css/music.css"
import { client } from '../client'
import { motion, useScroll, useTransform, AnimatePresence } from "framer-motion"
import musicHeader from "../assets/launderette20002.jpg"


const Music = () => {

const [musicData, setMusicData] = useState(null)
const [filtered, setFiltered] = useState()
const [activeCategory, setCategory] = useState(3)
const [activeVideo, setVideo] = useState(false)

const targetRef = useRef()

const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end start"]
})

const y = useTransform(scrollYProgress, [0, 1], [0, -200])
const whatY = useTransform(scrollYProgress, [0, 1], [0, 200])

useEffect(() => {
    client.fetch(`*[_type == "music"]{
        date,
        category,
        artist,
        title,
        "imageUrl": image.asset->url,
        apple,
        spotify,
        youtube
    }`)
    .then((data) => setMusicData(data))
    .catch(console.error)
}, [])

let title = {
    closed: { opacity: 1 },
    open: { opacity: 1, transition: { delayChildren: 1.2, staggerChildren: 0.1 }}
}

let letters = {
    closed: { y: "24vw" },
    open: { y: 0, transition: { duration: 1, ease: [0.5, 0.7, 0.05, 1] } }
}


useEffect(() => {
    if (activeCategory === 3) {
        setFiltered(musicData)
        return
    }
    const filt = musicData.filter((music) =>
    music.category.toString().includes(activeCategory)
    )
    setFiltered(filt)
}, [ musicData, activeCategory ])

const header = { 
    open: { height: "100%" },
    closed: { height: "100vh" }
}



  return (
    <AnimatePresence mode="wait">
        
    <div className="music-container">
        <div className='filter-container'>
            <motion.button initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0, transition: { delay: 1, duration: 1.6 }}} className={activeCategory === 3 ? "active" : ""} onClick={() => {setCategory(3)}}>All</motion.button>
            <motion.button initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0, transition: { delay: 1, duration: 1.8 }}} className={activeCategory === 0 ? "active" : ""} onClick={() => {setCategory(0)}}>Albums</motion.button>
            <motion.button initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0, transition: { delay: 1, duration: 2.0 }}} className={activeCategory === 1 ? "active" : ""} onClick={() => {setCategory(1)}}>Singles</motion.button>
            <motion.button initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0, transition: { delay: 1, duration: 2.2 }}} className={activeCategory === 2 ? "active" : ""} onClick={() => {setCategory(2)}}>Remixes</motion.button>
        </div>
        <div className="something"></div>
        <motion.div variants={header} animate={ activeVideo ? "open" : "closed" } className="music-spacer">
        <motion.div variants={header} animate={ activeVideo ? "open" : "closed" } className="music-header">
        { activeVideo ? 
        <div className='music-video-grid'><div className="music-video-spacer"></div>
        <div className="music-video-wrapper">
        <iframe className='youtube-video' src={activeVideo} title="YouTube Video" autoplay={"true"} color={"white"} allowFullScreen /> 
        </div>
        </div>
        : 
        <div className='music-header' style={{ overflow: "hidden", width: "100vw", height: "100vh", zIndex: 200 }}>
        <div className="black-overlay"></div>
        <motion.div style={{ zIndex: 200, y }}>
            <motion.div className="four">
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.5, duration: 0.5 }}}>0</motion.div>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.7, duration: 0.5 }}}>4</motion.div>
            </motion.div>
            <motion.div variants={ title } initial="closed" animate="open" className="music-title" style={{ zIndex: 200, display: "flex", overflow: "hidden", height: "22vw" }}>
                <motion.div variants={ letters }>M</motion.div>
                <motion.div variants={ letters }>U</motion.div>
                <motion.div variants={ letters }>S</motion.div>
                <motion.div variants={ letters }>I</motion.div>
                <motion.div variants={ letters }>C</motion.div>
            </motion.div>
        </motion.div>
        <motion.img initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.5, duration: 1 }}} ref={targetRef} src={musicHeader} width="100%" height="100%" style={{ objectFit: "cover", position: "absolute", top: 0, y: whatY }} /> 
        </div>
        }
        </motion.div>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2 } }} style={{ backgroundColor: "black", zIndex: 1000 }} layout>
            { filtered && filtered
            .sort((a, b) => a.date < b.date ? 1 : -1)
            .map(( music ) => { 
                return (
                    <MusicTrack  
                    setVideo={setVideo}
                    activeVideo={activeVideo}
                    id={music.date}
                    key={music.date} 
                    artist={music.artist} 
                    title={music} 
                    image={music.imageUrl}
                    category={music.category}
                    youtube={music.youtube}
                    date={music.date}
                    /> 
                )}
                )
            }
        </motion.div>
        <motion.div initial={{ height: 0 }} animate={{ height: 160, transition: { delay: 2 } }} className='bottom-spacer'></motion.div>
    </div>
    </AnimatePresence>
  )
}

export default Music