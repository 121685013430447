import React, { Suspense, useState, useEffect } from 'react'
import '../style.css'
import ExperienceContainer from '../components/ExperienceContainer'
import TourSection from './TourSection';
import LatestButton from '../components/LatestButton';
import ScrollDown from '../components/ScrollDown';
import { AnimatePresence, motion, useScroll, useTransform } from 'framer-motion';
import Galantis from './Galantis';
import Footer from './Footer';
import LatestSection from './LatestSection';
import { client } from '../client'
import Popup from './Popup';

const Home = ({ overlay, setOverlay }) => {

  const [ latest, setLatest ] = useState([0])
  const [ smart, setSmart ] = useState()
  const [ latestImage, setLatestImage ] = useState([0])

  let { scrollY } = useScroll()
  let opacity = useTransform(scrollY, [0, 300], [1, 0])

  useEffect(() => {
    client.fetch(`*[_type == "latestrelease"]{
        smarturl,
        "imageUrl": image.asset->url,
    }`)
    .then((data) => setLatest(data))
    .catch(console.error)
}, [])

useEffect(() => {
    const initUrl = async () => {
        await latest
        setSmart(latest[0].smarturl)
        setLatestImage(latest[0].imageUrl)
    }
    initUrl()
}, [latest]) 
 
  return (
    <>
    <AnimatePresence mode="wait">
    {/* <Popup /> */}
      <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2, duration: 0.5 } }}>
        <LatestButton smart={ smart } scrollY={ scrollY } opacity={ opacity } />
        <motion.div style={{ opacity }} className="gradient2"></motion.div>
        <Suspense>
          <motion.div style={{ opacity }} className="experience">
            <ExperienceContainer  />
          </motion.div>
        </Suspense>
        <ScrollDown scrollY={ scrollY } />
        <Galantis overlay={ overlay } setOverlay={ setOverlay } />
        <TourSection />
        <LatestSection smart={ smart } latestImage={ latestImage } overlay={ overlay } setOverlay={ setOverlay } />
        <Footer />
      </motion.div>
    </AnimatePresence>
      </>
  )
}

export default Home