import React from 'react'
import { motion } from "framer-motion"

const PharmacyText = ({ text }) => {

const words = text.split(" ")

const contain = { 
    closed: { opacity: 0 }, 
    open: {
        opacity: 1,
        transition: { staggerChildren: 0.03, delayChildren: 0.03 }
    }
}

const children = { 
    open: { opacity: 1, y: -5, transition: { duration: 0.5 } }, 
    closed: { opacity: 0 }
}


  return (
    <motion.div style={{ position: "absolute", display: "flex", flexWrap: "wrap", alignItems: "flex-start" }} variants={ contain } initial="closed" animate="open">
            { words.map((word, index) => (
                <motion.span variants={ children } style={{ marginRight: "clamp(5px, 2vw, 8px)", fontSize: "clamp(0.8rem, 1.2vw, 1rem)" }} key={ index }>{ word }</motion.span>
            ))}
    </motion.div>
  )
}

export default PharmacyText