import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/galantis.svg"
import "../css/logo.css"

const Logo = () => {

  return (
    <div className='logo-main'>
      <Link to="/">
        <img src={ logo } alt="logo" />
      </Link>
    </div>
  )
}

export default Logo